.dealer p,
.dealer li {
	color: #686e78;
	@apply --m;
	font-size: 20px;
}

.dealer h2 {
	color: #01102a;
}

.dealerTable {
	width: 100%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	border: 1px solid #c9d3db;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	margin-bottom: 35px;
}

.dealerTable__heading {
	text-transform: uppercase;
	background-color: #eff4f8;
	color: #5a6a77;
	font-family: "Myriad Pro";
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 3px;
	padding: 12px;
	text-align: center;
}

.dealerTable__line {
	width: 100%;
	display: flex;
}

@media (width <= 641px) {
	.dealerTable__line {
		flex-wrap: wrap;
	}
}

.dealerTable__line:not(:last-child) {
	border-bottom: 1px solid #c9d3db;
}

@media (width <= 641px) {
	.dealerTable__line:not(:last-child) {
		border-bottom: 2px solid #c9d3db;
	}
}

.dealerTable__title {
	width: 260px;
	max-width: 100%;
	padding: 12px 22px;
	border-right: 1px solid #c9d3db;
	background-color: white;
	color: #2585fb;
	font-family: "Myriad Pro";
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 3px;
}

@media (width <= 641px) {
	.dealerTable__title {
		width: 100%;
		border-right: none;
		border-bottom: 1px solid #c9d3db;
	}
}

.dealerTable__data {
	width: 100%;
	max-width: calc(100% - 260px);
	padding: 12px 22px;
	background-color: white;
	color: #40464d;
	font-family: "Myriad Pro";
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 3px;
}

@media (width <= 641px) {
	.dealerTable__data {
		max-width: 100%;
	}
}