.index--news {
	background-color: #dfe4e8;
}

.news-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 50px;
}

@media (width >= 1600px) {
	.news-item {
		flex-basis: calc(20% - 20px);
	}	
}

.news-item {
	flex-basis: calc(25% - 20px);
	margin-bottom: 40px;
}

@media (width <= 1199px) {
	.news-wrapper {
		flex-wrap: wrap;
	}

	.news-item {
		flex-basis: calc(50% - 20px);
	}	
}

@media (width <= 767px) {
	.news-item {
		flex-basis: 100%;
	}	
}

.news-item a:first-child {
	text-decoration: none;
}

.news-item a:first-child h3 {
	line-height: normal;
	@apply --transitionOpacityStart;
}

.news-item a:first-child:hover h3 {
	@apply --transitionOpacityEnd;
}

.news-item h3 {
	color: #166ed9;
	@apply --c;
	font-size: 18px;
}

.news-item p {
	color: #686e78;
	@apply --m;
	font-size: 16px;
}

.news-item a:last-child {
	color: #01102a;
	@apply --c;
	font-size: 14px;
	transition: color .3s;
}

.news-item a:last-child:hover {
	color: #166ed9;
}

.news-item a:last-child svg {
	display: inline-block;
	width: 6px;
	height: 11px;
	will-change: opacity;
	transition: opacity .3s, transform .2s .1s;
	opacity: 0.3;
	transform: rotate(180deg) translateX(0);
	margin-left: 5px;
}

.news-item a:last-child:hover svg {
	transform: rotate(180deg) translateX(-5px);
	opacity: 1;
}

.news-item a:last-child .pageFill {
	fill: black;
	transition: fill .3s;
}

.news-item a:last-child:hover .pageFill {
	fill: #166ed9;
}