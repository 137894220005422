.youtube {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	display: block;
	overflow: hidden;
	transition: all 200ms ease-out;
	cursor: pointer;
}

.youtube::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	z-index: 1;
}


.youtube .play {
	top: 0;
	left: 0;
	background-image: url(../img/video.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 75px 75px;
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 1;
	transition: opacity 0.3s;
}

.youtube:hover .play {
	opacity: 0.5;
}

.youtube-b-lazy-wrapper,
.video-wrapper {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
}

.youtube-b-lazy,
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}