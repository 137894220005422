@media (width >= 1200px) {
	.is-shifted.site-header__wrapper {
		transition: transform .2s, opacity .2s;
		transform: translateY(-100%);
		opacity: 0;
	}

	.is-shifted.is-fixed.site-header__wrapper {
		transform: translateY(0);
		opacity: 1;
		box-shadow: 1px 8px 4px -5px rgba(0, 0, 0, .5)
	}

	.is-fixed.site-header__wrapper {
		position: fixed;
		min-height: auto;
		width: 100%;
		box-shadow: 0 4px 18px rgba(105, 100, 99, .14);
		padding-left: 50px;
		padding-right: 50px;
		z-index: 2;
		background-color: #2585fb;
	}

	.site-header.extra-space  {
		/* margin-bottom: 83px; */
	}
}

.site-header {
	position: relative;
	z-index: 2;
}

.site-header--index {
	pointer-events: none;
}

.site-header__wrapper {
	pointer-events: all;
}

@media (width >= 1200px) {
	.site-header--index {height: 817px;}
	.site-header:not(.site-header--index) {min-height: 437px;}
}

@media (width <= 1599px) {
	.site-header:not(.site-header--index) {min-height: 499px;}
}

@media (width <= 1199px) {
	.site-header--index {height: 710px;}
	.site-header:not(.site-header--index) {min-height: 587px;}
}

@media (width <= 991px) {
	.site-header--index {height: 480px;}
	.site-header:not(.site-header--index) {min-height: 477px;}
}

@media (width <= 767px) {
	.site-header--index {height: 180px;}
	.site-header:not(.site-header--index) {min-height: 369px;}
}

.site-header:not(.site-header--index) {
	position: relative;
}

.site-header__bgi-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	content: "";
	z-index: -1;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.site-header--index .site-header__wrapper:not(.is-fixed) .site-header__bgi-wrapper {
	display: none;
}

.navbar-toggle + a svg {
	display: block;
	max-width: 100%;
	padding-top: 5px;
	padding-bottom: 5px;
}

@media (width <= 991px) {
	.navbar-toggle + a svg {
		height: 70px;
		margin-left: auto;
		margin-right: auto;
	}
}