.index--slider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 0;
}

.index--slider__heading {
	padding: 0 200px;
	color: white;
	@apply --c;
	font-size: 55px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
}

.index--slider__heading a {
	color: #FFF;
}

.index--slider__heading span {
	border-radius: 2px;
	line-height: 1;
	padding: 0 5px;
	margin-bottom: 3px;
	letter-spacing: 2px;
	text-shadow: 1px 2px 8px #05276d;
}

.index--slider__subheading {
	color: #05276d;
	@apply --m;
	padding: 0 150px;
	font-size: 26px;
	line-height: 40px;
	text-align: right;
	text-shadow: 0px 0px 2px white;
}

.index--slider__text {
	color: white;
	@apply --m;
	font-size: 22px;
	line-height: 40px;
	text-shadow: 0px 0px 2px #05276d;
	background-color: rgba(0, 80, 179, 0.69);
	display: inline;
	padding: 0 10px;
}

.index--slider__nav {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 0;
}

@media (width <= 1599px) {
	.index--slider__nav {
		top: 580px;
	}
}

@media (width <= 1199px) {
	.index--slider__heading {
		font-size: 45px;
	}
	.index--slider__subheading,
	.index--slider__text {
		display: none;
	}

	.index--slider__nav {
		top: 505px;
	}
}

@media (width <= 991px) {
	.index--slider__heading {
		padding: 0;
		text-align: center;
		align-items: center;
		font-size: 32px;
	}

	.index--slider__nav {
		display: none;
	}
}

@media (width <= 767px) {
	.index--slider__heading {
		display: none;
	}
}

.index--slider__nav span {
	display: block;
	width: 24px;
	height: 24px;
	position: absolute;
	top: calc(50% - 12px);
	cursor: pointer;
	transform: scale(1);
	transition: transform 1s;
}

.index--slider__nav>span:hover {
	transform: scale(1.5);
}

.index--slider__nav--left {
	left: 80px;
}

.index--slider__nav--right {
	right: 80px;	
}

.index--slider__nav--right svg {
	transform: rotate(180deg);
}

.index--slider__nav span svg {
	width: 100%;
	height: 100%;
}

.index--slider__nav .pageFill {
	fill: white;
	transition: fill .3s;
}

.index--slider__nav span:hover .pageFill {
	fill: #f6c97c;
}

@media (width >= 1200px) {
	.index--slider__slide {
		height: 817px;
		padding-top: 437px;
		background-position:center top;
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;
	}
}

@media (width <= 1199px) {
	.index--slider__slide {
		height: 710px;
		padding-top: 440px;
		background-position: center;
		background-size: cover;
	}
}

@media (width <= 991px) {
	.index--slider__slide {
		height: 480px;
		padding-top: 250px;
		background-position: inherit;
	}
}

@media (width <= 767px) {
/* 	.index--slider__slide {
		height: 425px;
		background-size: cover;
		background-position: center center;
		padding-top: 0;
	} */
	
	.index--slider__wrapper .index--slider__slide {
		height: 250px;
		background-image: none!important;
		padding-top: 0;
	}
}

@media (width >= 992px) {
	.index--slider__indicator {
		display: block;
		right: 120px;
		top: -30px;
		position: absolute;
	}

	.index--slider__indicator span {
		@apply --c;
		color: white;
		font-size: 20px;
		display: inline-block;
		position: static;
		width: auto;
		height: auto;
		padding: 2px;
		filter: drop-shadow(1px 1px 2px rgba(0,0,0,.5));
	}

	.index--slider__indicator .index--slider__indicator--current {
		color: #ffce78;
		font-size: 34px;
	}
}