.header-bottom-right__telbar {
	width: 100%;
	margin-bottom: 10px;
	text-align: right;
}

.header-bottom-right__telbar p,
.header-bottom-right__telbar a {
	color: white;
	@apply --m;
	font-size: 15px;
}

.header-bottom-right__telbar a {
	white-space: nowrap;
	@apply --transitionOpacityStart;
}

.header-bottom-right__telbar a:hover {
	@apply --transitionOpacityEnd;
}

.header-bottom-right__telbar a span {
	font-size: 16px;
}

.is-fixed .header-bottom-right__telbar {
	display: none;
}