@font-face {
	font-family: "Code Pro LC";
	font-display: swap;
	src: url(../fonts/CodeProLC.woff);
}

@font-face {
	font-family: "Code Pro Bold LC";
	font-display: swap;
	src: url(../fonts/CodeProBoldLC.woff);
}

@font-face {
	font-family: "Myriad Pro";
	font-display: swap;
	src: url(../fonts/MyriadProRegular.woff);
}

@font-face {
	font-family: "Myriad Pro Bold";
	font-display: swap;
	src: url(../fonts/MyriadProSemiBold.woff);
}