.collapsing {
	transition: none;
}

@media (width <= 991px) {
	.navbar-toggle {
		background-color: #f1b44c;
		border-radius: 2px;
		width: 40px;
		height: 100%;
		position: absolute;
		margin: 0;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transform: translateX(315px);
		transition: transform .3s .3s;
	}

	.navbar-toggle.collapsed {
		transform: translateX(0);
		transition: transform .3s 0s;
	}

	.navbar-toggle:hover {
		transform: translateX(315px) scaleX(1);
	}

	.navbar-toggle.collapsed:hover {
		transform: translateX(0) scaleX(1.2);
	}

	.navbar-toggle .icon-bar {
		width: 23px;
		height: 1px;
		background-color: white;
		transition: transform .3s;
	}

	.navbar-toggle .icon-bar + .icon-bar {
		margin-top: 9px;
	}

	.navbar-toggle .icon-bar:nth-child(2) {
		transform: translate(0,10px) rotate(45deg) scaleX(1);
	}

	.navbar-toggle .icon-bar:nth-child(4) {
		transform: translate(0,-10px) rotate(-45deg) scaleX(1);
	}

	.navbar-toggle .icon-bar:nth-child(3) {
		opacity: 0;
	}

	.navbar-toggle:hover .icon-bar:nth-child(3) {
		opacity: 1;
	}

	.navbar-toggle.collapsed .icon-bar:nth-child(2),
	.navbar-toggle.collapsed .icon-bar:nth-child(4) {
		transform: translate(0,0) rotate(0) scaleX(1);
	}

	.navbar-toggle.collapsed .icon-bar:nth-child(3) {
		opacity: 1;
	}

	.navbar-toggle.collapsed:hover .icon-bar:nth-child(2) {
		transform: translate(7px,6px) rotate(45deg) scaleX(.5);
	}

	.navbar-toggle.collapsed:hover .icon-bar:nth-child(4) {
		transform: translate(7px,-6px) rotate(-45deg) scaleX(.5);
	}

	.navbar-toggle:hover .icon-bar:nth-child(2) {
		transform: translate(-7px,6px) rotate(-45deg) scaleX(.5);
	}

	.navbar-toggle:hover .icon-bar:nth-child(4) {
		transform: translate(-7px,-6px) rotate(45deg) scaleX(.5);
	}
}

@media (width <= 479px) {
	.navbar-toggle {
		transform: translateX(280px);
	}

		.navbar-toggle:hover {
		transform: translateX(280px) scaleX(1);
	}

}
