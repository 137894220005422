.item__video.index--about__video {
	flex-basis: calc(50% - 35px);
	width: calc(50% - 35px);
	margin-top: 140px;
	padding-top: 0;
	border-top: none;
	max-width: 100%;
}

@media (width <= 1199px) {
	.item__video.index--about__video {
		flex-basis: 100%;
		margin-top: 0;
		margin-bottom: 50px;
	}
}

.item__video .index--about__advantages > a {
	margin-top: 20px;
}