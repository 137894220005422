.page-heading {
	margin-top: -70px;
	max-width: 45%;
	margin-left: auto;
	margin-right: auto;
}

.is-fixed .page-heading {
	display: none;
}

@media (width <= 1199px) {
	.page-heading {
		margin-top: 0!important;
		max-width: none!important;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.page-heading p {
	color: white;
	@apply --c;
	font-size: 40px;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 0;
}

@media (width <= 767px) {
	.page-heading p {
		font-size: 30px;
	}	
}

@media (width <= 479px) {
	.page-heading p {
		font-size: 20px;
	}	
}