.page404 {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	position: relative;
	display: flex;
}

.page404 > picture,
.page404 > picture > img {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-position: center;
	object-fit: cover;
}

.page404__content {
	z-index: 1;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.page404__heading {
	margin-top: 30px;
	margin-bottom: 15px;
	padding-left: 15px;
	padding-right: 15px;
	color: #ffffff;
	font-family: "Myriad Pro", sans-serif;
	font-size: 30px;
	font-weight: 400;
	text-align: center;
}

@media (width <= 767px) {
	.page404__heading {
		margin-top: 20px;
		margin-bottom: 10px;
		font-size: 24px;
	}
}

.page404__subheading {
	font-family: "Myriad Pro", sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	margin-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
}

@media (width <= 767px) {
	.page404__subheading {
		font-size: 16px;
	}
}

.page404__subheading {
	color: #ffffff;
}

.page404__img {
	max-width: 80%;
	height: auto;
	margin-top: 30px;
	margin-bottom: 30px;
}

.page404__goHomepage {
	color: #ffecdb;
	font-family: "Myriad Pro", sans-serif;
	font-size: 21px;
	font-weight: 400;
	line-height: 2;
	text-align: center;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 30px;
	transition: border-bottom-color .3s;
	border-bottom: 1px solid transparent;
}

.page404__goHomepage:hover {
	color: #ffecdb;
	border-bottom-color: currentColor;
}