.slider-img {
	flex-basis: 680px;	
	height: auto;
	position: relative;
}

.slider-img .carousel {
	position: absolute;
	bottom: 0;
}

.slider-img .carousel-inner {
	width: 100%;
	max-height: 510px;
}

.slider-img .carousel-inner img {
	width: 100%;
	height: 510px;
	object-fit: contain;
	object-position: left;
	padding-bottom: 40px;
}

.slider-img .carousel-caption {
	left: 0;
	right: auto;
	bottom: 0;
	padding-bottom: 0;
	text-shadow: none;
	text-align: center;
	width: 100%;
}

.slider-img .carousel-caption p {
	color: #01102a;
	@apply --c;
	font-size: 18px;
	text-transform: uppercase;
	line-height: 1;
	margin-bottom: 0;
}

@media (width <= 1599px) {
	.slider-img {
		flex-basis: 100%;
		order: -1;
	}

	.slider-img .carousel {
		position: static;
	}

	.slider-img .carousel-inner {
		width: 1170px;
		max-width: 100%;
		max-height: 850px;
		margin-left: auto;
		margin-right: auto;
	}

	.slider-img .carousel-inner img {
		object-fit: contain;
		object-position: center;
	}
}

@media (width <= 1199px) {
	.slider-img .carousel-inner {
		width: 90%;
	}

	.slider-img .carousel-caption {
		text-align: center;
		width: 100%;
	}
}

@media (width <= 991px) {
	.slider-img .carousel-inner {
		max-height: none;
	}

	.slider-img .carousel-inner img {
		height: auto;
	}
}