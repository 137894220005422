.header-bottom-menu {
	flex-basis: calc(100% - 720px);
	height: 50px;
}

@media (width >= 1600px) {
	.header-bottom-menu > .container {
		width: 100%;
	}
}

@media (width <= 1599px) {
	.header-bottom-menu {
		flex-basis: 100%;
		order: -1;
		margin-bottom: 30px;
	}
}

.header-bottom-menu nav {
	display: block;
	width: 100%;
}

.header-bottom-menu .navbar-nav {
	display: flex;
	position: relative;
	justify-content: space-between;
	width: 890px;
	margin-right: auto;
	margin-left: auto;
	float: none;
}

.header-bottom-menu .navbar-nav::before,
.header-bottom-menu .navbar-nav::after {
	display: none;
}

.header-bottom-menu .navbar-nav > li > a {
	padding-right: 0;
	padding-bottom: 10px;
	padding-left: 0;
	color: #ffce78;
	font-size: 18px;
	text-transform: uppercase;
	transition: color 0.3s;
	@apply --c;
}

.nav > li > a:hover,
.nav > li > a:focus {
	background-color: transparent;
	color: white;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	border-color: transparent;
	background-color: transparent;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	background-color: transparent;
}

.header-bottom-menu .dropdown.open,
.header-bottom-menu .dropdown.open a {
	position: static;
}

.header-bottom-menu .dropdown-menu {
	width: 100%;
	padding: 20px;
	border-radius: 0;
	background-color: #2585fb;
	box-shadow: none;
}

.header-bottom-menu .open > .dropdown-menu {
	display: flex;
	top: 90%;
	flex-wrap: wrap;
	border: none;
	box-shadow: 0 13px 51px #4b4b4b;
}

.header-bottom-menu .open > .dropdown-menu li {
	flex-basis: 50%;
}

.header-bottom-menu .open > .dropdown-menu li a {
	padding: 20px;
	color: white;
	font-size: 18px;
	text-transform: uppercase;
	white-space: normal;
	transition: color 0.3s;
	@apply --c;
}

.header-bottom-menu .open > .dropdown-menu li a:hover {
	color: #ffe5bb;
}

.is-fixed .header-bottom-menu {
	flex-basis: auto;
	flex-grow: 0.5;
	order: 0;
	margin-right: 5px;
	margin-bottom: 10px;
	margin-left: 5px;
}

.is-fixed .header-bottom-menu .container {
	width: 100%;
}

.is-fixed .header-bottom-menu .navbar-nav {
	width: auto;
}

.is-fixed .header-bottom-menu .navbar-nav > li {
	margin-right: 5px;
	margin-left: 5px;
}

@media (width <= 1599px) {
	.is-fixed .header-bottom-menu .navbar-nav > li > a {
		font-size: 17px;
	}
}

.is-fixed .header-bottom-menu .navbar-nav > li > a:hover,
.is-fixed .header-bottom-menu .navbar-nav > li.active > a,
.is-fixed .header-bottom-menu .navbar-nav > li.open > a {
	border-color: #f6c97c;
}
