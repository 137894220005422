.site-header-bottom {
	width: 100%;
	margin-top: 40px;
}

.site-header-bottom__wrapper {
	display: flex;
	justify-content: space-between;
}

@media (width <= 1599px) {
	.site-header-bottom__wrapper {
		flex-wrap: wrap;
	}
}

.is-fixed .site-header-bottom {
	margin-top: 10px;
}

.is-fixed .site-header-bottom__wrapper {
	align-items: center;
}

@media (width <= 991px) {
	.site-header-bottom {
		margin-top: 0;
	}

	.site-header-bottom__wrapper {
		display: block;
	}

	.header-bottom-logo p {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		max-width: 280px;
	}
}

@media (width <= 767px) {
	.site-header--index .header-bottom-logo p {
		color: #2585fb;
		text-shadow: none;
	}
}