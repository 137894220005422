.site-footer-top {
	display: flex;
	justify-content: space-between;
	padding-right: 30px;
}

@media (width <= 1199px) {
	.site-footer-top {
		flex-wrap: wrap;
	}
}

@media (width <= 1499px) {
	.site-footer-top {
		padding-right: 0;
	}
}