table {
	border: none!important;
	width: 100%!important;
	max-width: 100%!important;
	overflow-x: auto!important;
	min-height: 0.01%!important;
	display: block;
}


table > tbody > tr > td {
	padding: 20px 30px;
	line-height: 1.42857143;
	vertical-align: top;
	color: #0b0c0f;
	@apply --m;
	font-size: 16px;
	text-transform: none;
}



table > tbody > tr:nth-of-type(odd) {
	background-color: #f6f6f6;
}