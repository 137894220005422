.responsive-only {
	display: none;
}

.desktop-only {
	display: block!important;
}

@media (width <= 991px) {
	.responsive-only {
		display: block;
	}

	.desktop-only {
		display: none!important;
	}

	#headerBottomMenu {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		transform: translateX(-1000px);
		transition: transform .3s;
	}

	#headerBottomMenu.in {
		transform: translateX(0);
	}

	.navbar-collapse {
		box-shadow: none;
		border-top: 0;
		padding: 0;
	}

	.header-bottom-menu .navbar-nav {
		width: 315px;
		display: block;
		margin: 0;
		padding: 25px;
		background-color: rgba(6,29,58,.9);
	}

	.header-bottom-menu .nav > li > a {
		display: inline-block;
	}

	.header-bottom-menu .dropdown-menu {
		padding: 20px 0;
	}

	.header-bottom-menu .open > .dropdown-menu li {
		flex-basis: 100%;
	}

	.header-bottom-menu .open > .dropdown-menu li a {
		padding: 10px 0;
	}

	.site-header__search-form,
	.navbar-nav .menu-search-form {
		position: relative;
	}

	.site-header__search-form--responsive {
		background-color: rgba(6, 29, 58, .7);
		padding: 25px;
		position: relative;
	}

	.site-header__search-form--responsive input {
		width: 100%;
		height: 47px;
		border: 1px solid #b1d7cf;
		color: white;
		@apply --m;
		color: #a8b8cd;
		font-size: 20px;
		border-radius: 2px;
		padding: 5px 40px 5px 15px;
		background-color: transparent;
	}

	.site-header__search-form--responsive button {
		position: absolute;
		top: 35px;
		right: 35px;
		width: 30px;
		height: 30px;
		background-color: transparent;
		border: none;
	}

	.site-header__search-form--responsive svg {
		display: block;
		width: 18px;
		height: 18px;
	}

	.site-header__search-form--responsive .site-header__search-icon {
		fill: #b1d7cf;
	}

	.responsive-only .responsive-only__question {
		padding: 0 25px 25px;
		background-color: rgba(6, 29, 58, .7);
	}

	.responsive-only .responsive-only__question a {
		display: block;
		padding: 7px;
		background-color: #2585fb;
		border: 1px solid #2585fb;
		border-radius: 2px;
		color: white;
		@apply --c;
		font-size: 21px;
		text-align: center;
		text-transform: uppercase;
		transition: background-color .3s;
	}

	.responsive-only .responsive-only__question a:hover,
	.responsive-only .responsive-only__question a:active {
		background-color: transparent;
		color: #2585fb;
	}
}

@media (width <= 479px) {
	.responsive-only {
		width: 280px;
	}
	.header-bottom-menu .navbar-nav {
		width: 280px;
	}
}

@media (width >= 992px) {
	.collapse {
		display: block;
	}	
}