.salesBlock {
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: #FBFBFB;
	font-family: "Myriad Pro", sans-serif;	
}

.salesBlock-wrapper {
	display: flex;
	align-items: flex-start;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
}

.salesBlock__img {
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
}

.salesBlock__item {
	background-color: #E9E9E9;
	padding: 20px 20px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.salesBlock__item--second {
	background-color: #4296FE;
}

.salesBlock__itemImg {
	box-shadow: 0 5px 10px -2px rgba(42, 51, 57, .3);
	width: 255px;
	max-width: 100%;
	height: 170px;
	overflow: hidden;
	margin-bottom: 10px;
}

.salesBlock__itemImg img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.salesBlock__itemLink {
	text-decoration: none;
	font-size: 18px;
	color: #4296FE;
	border-bottom: 1px solid transparent;
	transition: border-bottom-color .3s;
}

.salesBlock__itemLink:hover {
	color: #4296FE;
	text-decoration: none;
	border-bottom-color: currentColor;
}

.salesBlock__item--second .salesBlock__itemLink,
.salesBlock__item--second .salesBlock__itemLink:hover {
	color: white;
	font-weight: bold;
}

.salesBlock__itemsPlus {
	font-size: 60px;
	font-weight: bold;
	color: #FFCE78;
	margin-left: 5px;
	margin-right: 5px;
}

.hasLabel {
	position: relative;
	overflow: hidden;
}

.hasLabel::after {
	content: attr(data-label);
	position: absolute;
	z-index: 1;
	font-size: 14px;
	font-weight: bold;
	color: #FFCE78;
	background-color: white;
	top: 0;
	right: 0;
	width: 100%;
	text-align: center;
	transform: translate(100px,18px) rotate(45deg);
	transform-origin: center;
}

.hasLabel--yellow-color::after {
	background-color: #FFF;
	color: #FFCE78;
}

.hasLabel--white-color::after {
	background-color: #FFCE78;
	color: #FFF;
}

.salesBlock__text {
	flex-basis: 50%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 20px;
	padding-right: 20px;
}

.salesBlock__textHeading {	
	font-size: 30px;
	font-weight: bold;
	color: #FFCE78;
	margin-bottom: 15px;
}

.salesBlock__textSubheading {	
	font-size: 18px;
	line-height: 1.5;
	color: #5A5A5A;
}

.salesBlock__textLink {
	color: #5A5A5A;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.5;
	border-bottom: 1px solid transparent;
	transition: border-bottom-color .3s;
	margin-left: 5px;
}

.salesBlock__textLink:hover {
	color: #5A5A5A;
	text-decoration: none;
	border-bottom-color: currentColor;
}

.counterTitle {
	margin-top: 10px;
	margin-bottom: 5px;
	color: #4296FE;
	font-size: 20px;
	font-weight: bold;
	display: block;
}

.counterBody {
	display: flex;
	align-items: center;
}

.counterBody > div {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 80px;
	position: relative;
}

.counterBody > div:not(:last-child) {
	margin-right: 20px;
}

.counterBody > div b {
	position: relative;
	width: 100%;
	z-index: 1;
	text-align: center;
	letter-spacing: 15px;
	left: 7.5px;
}

.counterBody > div::before {
	position: absolute;
	width: 100%;
	height: 100%;
	display: block;
	content: "";
	top: 0;
	left: 0;
	background-image: url(../img/counter.svg);
	background-position: top center;
	background-repeat: no-repeat;
	z-index: 0;
}

.counterBody > div:not(:last-child) b::after {
	content: ":";
	position: absolute;
	top: 18px;
	right: -19px;
	color: #FFCE78;
	font-size: 18px;
}

.counterBody > div b {
	color: #FFCE78;
	font-size: 48px;
}

.counterBody > div span {
	color: #4296FE;
	font-size: 20px;
	font-weight: bold;	
}

@media (width <= 1199px) {
	.salesBlock-wrapper {
		flex-wrap: wrap;
	}

	.salesBlock__img,
	.salesBlock__text {
		flex-basis: 100%;
		justify-content: center;
	}

	.salesBlock__text {
		align-items: center;
		margin-top: 40px;
	}

	.salesBlock__textSubheading,
	.counterTitle {
		text-align: center;
	}
}

@media (width <= 767px) {
	.salesBlock__img {
		flex-direction: column;
		align-items: center;
	}
}

@media (width <= 479px) {
	.salesBlock__img {
		padding-left: 0;
		padding-right: 0;
	}

	.salesBlock__text {
		
	}

	.counterBody {
		/* margin-left: -20px; */
		/* margin-right: -20px; */
	}
}