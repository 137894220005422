.header-bottom-right__questbar {
	text-align: right;
}

.header-bottom-right__questbar p {
	color: white;
	@apply --m;
	font-size: 16px;
}

.header-bottom-right__questbar .footer-email {
	color: white;
	border-bottom: 1px solid transparent;
	transition: border-bottom-color .3s;
	margin-bottom: 10px;
	display: inline-flex;
}

.header-bottom-right__questbar .footer-email:hover {
	border-bottom-color: white;
}

.is-fixed .header-bottom-right__questbar .footer-email {
	display: none;
}

.header-bottom-right__questbar a:last-child,
.header-bottom-right__questbar button {
	display: inline-block;
	padding: 5px 30px;
	line-height: normal;
	background-color: #2585fb;
	border: 1px solid #2585fb;
	border-radius: 2px;
	color: white;
	min-height: 40px;
	@apply --c;
	font-size: 14px;
	text-transform: uppercase;
	transition: background-color .3s, border-color .3s, color .3s;
}

.header-bottom-right__questbar a:last-child:hover,
.header-bottom-right__questbar button:hover {
	background-color: transparent;
}

.is-fixed .header-bottom-right__questbar a {
	border-color: white;
}

.is-fixed .header-bottom-right__questbar a:hover {
	border-color: #F6C97C;
	background-color: #F6C97C;
	color: #2585FB;
}


.is-fixed .header-bottom-right__questbar {
	margin-bottom: 10px;
}

.is-fixed .header-bottom-right__questbar p {
	display: none;
}

@media (width >= 1200px) {
	.is-fixed .header-bottom-right__questbar {
		display: none;
	}
}

@media (width >= 1355px) {
	.is-fixed .header-bottom-right__questbar {
		display: block;
	}
}