.blog--comments {
	padding-top: 30px;
	padding-bottom: 50px;
}

.blog--comments h2 {
	color: #01102a;
	@apply --c;
	font-size: 32px;
	position: relative;
	text-align: center;
}

.blog--comments h2::after {
	position: absolute;
	bottom: -20px;
	left: 50%;
	width: 90px;
	height: 4px;
	background-color: #2585FB;
	content: "";
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

@media (width <= 767px) {
	.blog--comments__wrapper {
		padding-left: 15px;
		padding-right: 15px;
	}	
}