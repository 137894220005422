.blog--news {
	background-color: #DFE4E8;
	padding-top: 50px;
	padding-bottom: 50px;
}

@media (width >= 1600px) {
	.blog--news .news-item {
		flex-basis: calc(33% - 20px);
	}	
}

.blog--news .news-item {
	flex-basis: calc(33% - 20px);
	margin-bottom: 40px;
}

@media (width <= 1199px) {
	.blog--news .news-wrapper {
		flex-wrap: wrap;
	}

	.blog--news .news-item {
		flex-basis: calc(33% - 20px);
	}	
}

@media (width <= 991px) {
	.blog--news .news-item {
		flex-basis: 100%;
		text-align: center;
	}

	.blog--news .news-item:not(:last-child) {
		margin-bottom: 50px;
	}
}

@media (width <= 767px) {
	.blog--news__wrapper {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.blog--news .news-item__date-views {
	display: inline-block;
	background-color: #FFF;
	padding: 5px;
	border-radius: 2px;
	margin-top: 10px;
	margin-bottom: 20px;
}

.blog--news .news-item__date-views span:first-child {
	display: inline-block;
	padding-right: 15px;
	border-right: 1px solid #686e78;
	margin-right: 15px;
}

.blog--news .news-item a:last-child {
	display: block;
}

.blog--news .news-item img {
	max-width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
}

.blog--news .news-item a {
	@apply --transitionOpacityStart;
}

.blog--news .news-item a:hover {
	@apply --transitionOpacityEnd;	
}