.remodal-is-locked.question-is-opening body {
	overflow-y: scroll;
	top: 0;
	right: 0;
	left: 0;
	height: 100%;
	position: fixed;
}

.remodal-overlay {
	background: rgba(21, 50, 86, 0.8);
	z-index: 2;
}

.fancybox-overlay {
	background: rgba(21, 50, 86, 0.8);
}

.remodal-wrapper {
	z-index: 3;
}

.remodal {
	background-color: #efefef;
	border-radius: 0;
	padding: 50px;
}

@media (width <= 767px) {
	.remodal {
		padding: 50px 20px;
	}	
}

.remodal-close {
	color: white;
	opacity: 0.6;
	right: -40px;
	border-radius: 50%;
	border: 1px solid white;
	width: 25px;
	height: 25px;
	transform: rotate(0deg);
	transition: opacity .3s, transform .3s;
}

.remodal-close:hover,
.remodal-close:focus {
	color: white;
	transform: rotate(180deg);
	opacity: 1;
}

@media (width <= 991px) {
	.remodal-close {
		border-color: dimgrey;
		right: 20px;
		top: 20px;
	}

	.remodal-close::before,
	.remodal-close::after {
		background-color: dimgrey;
	}
}

.remodal-close::before,
.remodal-close::after {
	width: 15px;
	height: 1px;
	background-color: white;
	content:"";
	position: absolute;
	top: 11px;
	left: 4px;
}

.remodal-close::before {
	transform: rotate(45deg);
}

.remodal-close::after {
	transform: rotate(-45deg);
}



.remodal h2 {
	@apply --c;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 20px;
	text-align: left;
	color: #2585fb;
	font-size: 21px;
}

.remodal form {
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}

.remodal .form-group {
	margin-bottom: 25px;
}

.remodal input,
.remodal textarea {
	background-color: white;
	border-radius: 0;
	border-color: #efefef;
	width: 100%;
	color: #8e8888;
	@apply --c;
	font-size: 16px;
	text-transform: none;
	margin-left: auto;
	margin-right: auto;
}

.remodal input:focus,
.remodal textarea:focus {
	border-color: #2585fb;
}

.remodal input {
	height: 50px;
}

.remodal textarea {
	resize: vertical;
	min-height: 100px;
}

.remodal form button {
	border: 1px solid #2585fb;
	height: 37px;
	padding: 10px 30px;
	color: white;
	background-color: #2585fb;
	border-radius: 2px;
	@apply --c;
	font-size: 17px;
	line-height: 1;
	text-transform: uppercase;
	transition: color .3s, background-color .3s, border-color .3s;
}

.remodal form  button:hover {
	color: #2585fb;
	background-color: transparent;
}