.site-footer__menu {
	display: flex;
	flex-basis: auto;
	flex-grow: 0.2;
	padding-left: 30px;
	padding-top: 30px;
	margin-right: auto;
	align-items: flex-start;
}

.site-footer__menu-column {
	flex-basis: auto;
	min-width: 120px;
	margin-right: 20px;
	flex-grow: 1;
	display: grid;
	align-items: start;
	grid-template-columns: min-content min-content;
	grid-column-gap: 20px;
	white-space: nowrap;
}

.site-footer__menu-column h5 {
	color: #01102a;
	@apply --c;
	font-size: 17px;
	margin-bottom: 20px;
	grid-column: 1/-1;
}

.site-footer__menu-column ul {
	padding-left: 0;
	list-style: none;
	grid-column: span 1;
}

.site-footer__menu-column ul:only-of-type {
	grid-column: 1/-1;
}

.site-footer__menu-column li {
	margin-bottom: 10px;
}

.site-footer__menu-column a {
	color: #2585fb;
	@apply --m;
	font-size: 16px;
	line-height: 1;
	@apply --transitionOpacityStart;
}

.site-footer__menu-column a:hover {
	@apply --transitionOpacityEnd;
}

@media (width <= 1199px) {
	.site-footer__menu {
		padding-right: 30px;
		flex-grow: 1;
		flex-basis: calc(100% - 370px);
	}

	.site-footer__menu-column {
		flex-grow: 1;
		max-width: none;
	}

	.site-footer__menu-column:last-child {
		margin-right: 0;
	}

}

@media (width <= 991px) {
	.site-footer__menu {
		flex-basis: 100%;
		margin-bottom: 30px;
		flex-wrap: wrap;
	}

	.site-footer__menu-column {
		grid-template-columns: 1fr;
	}

	.site-footer__menu-column ul:first-of-type {
		margin-bottom: 0;
	}
}

@media (width <= 767px) {
	.site-footer__menu {
		display: none;
	}
}