.about--news-inline__wrapper {
	display: flex;
	background-color: #DFE4E8;
}

@media (width <= 767px) {
	.about--news-inline__wrapper {
		flex-wrap: wrap;
	}
}

.about--news-inline__news {
	flex-basis: 75%;
}

@media (width <= 1199px) {
	.about--news-inline__news {
		flex-basis: 60%;
	}
}

@media (width <= 767px) {
	.about--news-inline__news {
		flex-basis: 100%;
	}
}

.about--news-inline__news .news-item {
	flex-basis: auto;
	padding-right: 100px;
}

.about--news-inline__news .heading-wrapper {
	padding-right: 100px;
}

@media (width <= 1599px) {
	.about--news-inline__news .news-item,
	.about--news-inline__news .heading-wrapper {
		padding-right: 40px;
	}
}

@media (width <= 767px) {
	.about--news-inline__news .news-item,
	.about--news-inline__news .heading-wrapper {
		padding-right: 0
	}
}


.about--news-inline__video {
	flex-basis: 25%;
	padding-left: 40px;
	width: 25%;
}

@media (width <= 1199px) {
	.about--news-inline__video {
		flex-basis: 40%;
	}
}

@media (width <= 767px) {
	.about--news-inline__video {
		flex-basis: 100%;
		padding-left: 0;
	}
}

.about--news-inline__video .index--about__video {
	margin-top: 70px;
	border-top: none;
}

@media (width <= 1599px) {
	.about--news-inline__video .index--about__video {
		margin-top: 50px;
	}	
}

@media (max-width: 1499px) {
	.about--news-inline__video .index--about__video {
		flex-basis: 100%;
		max-width: none;
	}	
}

.about--news-inline__video .index--about__video .youtube .play {
	background-size: 75px 75px;
}

.about--news-inline__video .youtube-link {
	width: 200px;
	height: 34px;
	background-color: #2585fb;
	border: 1px solid #2585fb;
	border-radius: 2px;
	display: flex;
	margin-left: auto;
	margin-right: auto;
	margin-top: 25px;
	justify-content: center;
	align-items: center;
	color: white;
	@apply --c;
	font-size: 14px;
	text-transform: uppercase;
	transition: background-color .3s, color .3s;	
}

.about--news-inline__video .youtube-link:hover {
	background-color: transparent;
	color: #2585fb;
}

@media (max-width: 1199px) {
	.about--news-inline__video .youtube-link {
		width: 100%;
		height: auto;
		padding: 10px;
	}
}

@media (width <= 767px) {
	.about--news-inline__video .youtube-link {
		width: 200px;
	}
}