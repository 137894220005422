.item--similar h2 {
	color: #01102a;
	@apply --c;
	font-size: 32px;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 5px;
	position: relative;
}


.item--similar .heading--center::after {
	position: absolute;
	bottom: -20px;
	left: 50%;
	width: 90px;
	height: 4px;
	background-color: #2585FB;
	content: "";
	transform: translateX(-50%);
}