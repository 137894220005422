.header-bottom-right__langbar {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 25px;
}

.header-bottom-right__langbar .lang {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 33px;
	height: 33px;
	border: 1px solid #d9d6d6;
	border-radius: 50%;
	color: white;
	@apply --c;
	font-size: 12px;
	text-transform: uppercase;
	background-color: transparent;
	margin-right: 15px;
	line-height: 1;
	opacity: 1;
	transition: background-color .3s, border-color .3s, opacity .3s;
}

.header-bottom-right__langbar .lang:last-child {
	margin-right: 0;
}

.header-bottom-right__langbar .lang:hover {
	border-color: transparent;
	opacity: 0.7;
	background-color: #2585fb;
}

.header-bottom-right__langbar .lang.active {
	border-color: transparent;
	opacity:1;
	background-color: #2585fb;
	cursor: default;
}

.is-fixed .header-bottom-right__langbar {
	display: none;
}

@media (width <= 991px) {
	.header-bottom-right__langbar {
		background-color: rgba(6, 29, 58, .7);
		margin-bottom: 0;
		padding-top: 20px;
		padding-bottom: 20px;
		justify-content: center;
	}

	.header-bottom-right__langbar .lang,
	.header-bottom-right__langbar .lang:last-child {
		margin-left: 10px;
		margin-right: 10px;
	}
}