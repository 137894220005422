.sidebar {
	/* flex-basis: 555px; */
	flex-basis: 425px;
	border-right: 1px solid #adb4b9;
}

@media (width <= 1599px) {
	.sidebar {
		flex-basis: 400px;
	}	
}

@media (width <= 991px) {
	.sidebar {
		flex-basis: 360px;
	}		
}

@media (width <= 767px) {
	.sidebar {
		display: none;
	}
}

@media (width >= 768px) {
	.sidebar {
		position: relative;
	}
	.youtubeBanner + div{
		position: sticky;
		top: 30px;
	}
}

@media (width >= 1200px) {
	.youtubeBanner + div{
		top: 140px;
	}
}

.sidebar ul {
	padding-left: 0;
	padding-right: 85px;
	list-style: none;
}

@media (width <= 991px) {
	.sidebar ul {
		padding-right: 45px;
	}		
}

.sidebar ul li {
	border-bottom: 1px solid #dfe4e8;
	padding-top: 10px;
	padding-bottom: 10px;
}

.sidebar ul li a {
	@apply --c;
	font-size: 17px;
	text-transform: uppercase;
	color: #626468;
	padding-top: 10px;
	padding-bottom: 10px;
	display: inline-block;
	position: relative;
	transition: transform .3s, color .3s;
}

.sidebar ul li a:hover,
.sidebar ul li.active a {
	transform: translateX(30px);
	color: #103d79;
}

.sidebar ul li a::before {
	position: absolute;
	top: 16px;
	left: -30px;
	width: 7px;
	height: 12px;
	content: "";
	opacity: 0;
	transform: translateX(-10px);
	transition: opacity .3s .1s, transform .3s .1s;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAMAAACZS1ciAAAAPFBMVEUAAAABECoBECoBECoBECoBECoBECoBECoBECoBECoBECoBECoBECoBECoBECoBECoBECoBECoBECoBECproi+GAAAAE3RSTlMA4xajmOvCsR/11r+3qI1VSw4Jn7q3ZAAAADtJREFUCNdjEGBmAAM2YR4wzcEozAtmMLEKs0BEOIX5ICKMEBEhRmFmhAJBKI9LmB0kyS3MBlbMzw6mAE3+AZuQF03LAAAAAElFTkSuQmCC');
}

.sidebar ul li a:hover::before,
.sidebar ul li.active a::before {
	opacity: 1;
	transform: translateX(0);
}