.pagination__wrapper {
	width: 100%;
	text-align: center;
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 40px;
}

.pagination li:first-child span,
.pagination li:last-child span {
	height: 24px;
	width: 15px;
	display: block;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAYCAMAAAAS21dbAAAAQlBMVEUAAABNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktkaHjP4AAAAFXRSTlMAZE2tW1NMO5h+dXFqRO7suY5eWSEPOVGJAAAAYElEQVQY04WQSRKAIAwEAREEwT3//6okBxIol751zyUVJdCTkmQAGRwUhqoGkNCsoHn90rNVSzq+6NFqQFt8vWNHXy9ViYB4DvNzyBwSBcNBU3B9ML8hdWFTjKX3CWLRGyKwCYvcbEUAAAAAAElFTkSuQmCC');
}

.pagination li:last-child span {
	transform: rotate(180deg);
}

.pagination li {
	width: 50px;
	display: inline-block;
	text-align: center;
}

.pagination li a {
	@apply --c;
	font-size: 30px;
	line-height: 1;
	color: #b1b3b7;
	transition: color .3s;
}

.pagination li.active a,
.pagination li a:hover {
	color: #2585fb;
}

.pagination > li > a,
.pagination > li > span {
	border: none;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus  {
	background-color: transparent;
}