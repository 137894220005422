.index--awards {
	background-color: #F3F5F7;
}

.award-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 50px;
}

.award-item {
	flex-basis: calc(12.5% - 20px);
}

@media (width <= 1199px) {
	.award-wrapper {
		flex-wrap: wrap;
	}

	.award-item {
		flex-basis: calc(25% - 20px);
	}	
}

@media (width <= 767px) {
	.award-item {
		flex-basis: calc(50% - 20px);
	}	
}

.award-item img {
	width: 100%;
	max-width: 150px;
	height: auto;	
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.award-item p {
	color: #424040;
	@apply --m;
	font-size: 16px;
	text-align: center;
	margin-top: auto;
}