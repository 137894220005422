.site-footer__form {
	flex-basis: auto;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
}

.site-footer__form p {
	color: #01102a;
	@apply --m;
	font-size: 16px;
	margin-top: 0;
	margin-bottom: 0;
	white-space: nowrap;
	margin-right: 20px;
	text-align: right;
}

.site-footer__form form {
	width: 100%;
	max-width: 280px;
	height: 36px;
	border: 1px solid #2585fb;
	border-radius: 2px;
	position: relative;
}

.site-footer__subscription-email {
	width: 100%;
	height: 100%;
	border: none;
	padding-left: 10px;
	padding-right: 46px;
	color: #7d838e;
	@apply --m;
	font-size: 16px;
}

.site-footer__subscription-submit {
	width: 36px;
	height: 36px;
	background-color: #2585fb;
	border-radius: 2px;
	border: 1px solid #2585fb;
	position: absolute;
	top: -1px;
	right: -1px;
	transition: background-color .3s;
}

.site-footer__subscription-submit:hover,
.site-footer__subscription-submit:active {
	background-color: #FFF;
}

.site-footer__form svg {
	position: absolute;
	pointer-events: none;
	top: 11px;
	right: 13px;
	transform: rotate(180deg);
}

.site-footer__form .pageFill {
	fill: white;
	transition: fill .3s;
}

.site-footer__subscription-submit:hover + svg .pageFill,
.site-footer__subscription-submit:active + svg .pageFill {
	fill: #2585fb;
}

@media (width <= 1199px) {
	.site-footer__form {
		flex-basis: 100%;
		order: -1;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding-left: 20px;
		align-items: center;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.site-footer__form br {
		/* display: none; */
	}

	.site-footer__form form {
		width: auto;
		flex-basis: auto;
		min-width: 260px;
		margin: 0 10px;
	}
}

@media (width <= 991px) {
	.site-footer__form {
		margin-top: 0;
		margin-bottom: 30px;
	}


}

@media (width <= 640px) {
	.site-footer__form p {
		width: auto;
		flex-basis: auto;
		margin: 0 10px 10px;
		text-align: center;
		white-space: normal;
	}
}