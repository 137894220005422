.inline-request {
	background-color: #2585FB;
}

.inline-request p {
	color: white;
	@apply --c;
	font-size: 23px;
	line-height: 46px;
	margin-bottom: 0;
}

.inline-request__wrapper {
	display: flex;
	justify-content: space-between;
}

.form-inline {
	display: flex;
	flex-grow: 1;
	margin-left: 30px;
	margin-right: 30px;
}

.form-inline .select-wrapper {
	flex-grow: 1;
	margin-right: 10px;
	position: relative;

}

.form-inline .select-wrapper::after {
	position: absolute;
	content: "";
	height: 44px;
	width: 48px;
	background-color: #2585FB;
	top: 1px;
	right: 1px;
	border-left: 1px solid white;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../img/option.png);
	pointer-events: none;
}

.form-inline .form-control {
	width: 100%;
	height: 46px;
	color: white;
	@apply --c;
	font-size: 17px;
	text-transform: uppercase;
	border: 1px solid white;
	border-radius: 2px;
	background-color: transparent;
}

.form-inline .form-control option {
	background-color: #103a6e;
}

.form-inline-btn {
	line-height: normal;
	text-align: center;
	color: #2585fb;
	@apply --c;
	font-size: 18px;
	text-transform: uppercase;
	width: 138px;
	height: 46px;
	background-color: white;
	border-radius: 2px;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color .3s, border-color .3s, color .3s;
}

.form-inline-btn:hover {
	border-color: #103a6e;
	background-color: transparent;
	color: white;
}

.inline-request .inline-send-request {
	color: #ddecff;
	line-height: normal;
	@apply --c;
	font-size: 18px;
	text-transform: uppercase;
	background-color: #103a6e;
	border: 1px solid #103a6e;
	border-radius: 2px;
	width: 242px;
	height: 46px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color .3s;
}

.inline-request .inline-send-request:hover {
	text-decoration: none;
	color: #ddecff;
	background-color: transparent;
}


@media (width <= 1199px) {
	.inline-request__wrapper {
		flex-wrap: wrap;
	}

	.inline-request p {
		flex-basis: 100%;
		text-align: center;
		line-height: 1;
		margin-bottom: 15px;
	}

	.form-inline {
		margin-left: 0;
		margin-right: 15px;
	}

	.inline-send-request {
		margin-left: 15px;
	}
}

@media (width <= 767px) {
	.form-inline {	
		margin-right: 0;
		margin-bottom: 20px;
		flex-basis: 100%;
	}

	.inline-send-request {
		margin-left: auto;
		margin-right: auto;
	}
}

@media (width <= 479px) {
	.form-inline {
		flex-wrap: wrap;
	}

	.select-wrapper {
		flex-basis: 100%;
		margin-bottom: 15px;
	}

	.form-inline-btn {
		margin-left: auto;
		margin-right: auto;
	}
}