.card {
	display: flex;
	flex-basis: 250px;
	flex-direction: column;
	max-width: 250px;
	margin-right: 15px;
	margin-bottom: 40px;
	margin-left: 15px;
}

/* @media (width <= 991px) {
	.card {
		flex-basis: calc(50% - 30px);
		max-width: calc(50% - 30px);
	}	
} */

@media (width <= 767px) {
	.card a b {
		font-size: 18px;
	}
}

/* @media (width <= 479px) {
	.card {
		flex-basis: 100%;
		max-width: 100%;
	}	
} */

.card a {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	@apply --transitionOpacityStart;
}

.card a:last-child {
	/* margin-top: auto; */
}

.card a:hover {
	@apply --transitionOpacityEnd;
}

.card img {
	width: 100%;
	height: auto;
}

.card a b,
.card a mark {
	display: inline-block;
	margin: 10px 7px;
}

.card a b {
	color: #01102a;
	font-size: 19px;
	@apply --c;
}

.card a mark {
	padding: 5px 10px;
	color: white;
	font-size: 14px;
	line-height: 1;
	@apply --c;
}

.label--novelty a mark {
	background-color: #e5b768;
}

.card p {
	margin-top: 10px;
	margin-bottom: 0;
	padding-top: 20px;
	border-top: 1px solid rgba(0, 0, 0, 0.21);
	color: #5b5a5a;
	font-size: 20px;
	line-height: 1.2;
	@apply --m;
}

@media (width <= 641px) {
	.card p {
		margin-top: 0;
		padding-top: 10px;
		font-size: 18px;
		text-align: center;
	}
}
