.about--text__cert-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
}

.about--text__cert-wrapper a {
	flex-basis: calc(25% - 20px);
	max-width: calc(25% - 20px);
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 20px;
	max-height: 300px;
	overflow: hidden;
	@apply --transitionOpacityStart;
}

@media (width <= 991px) {
	.about--text__cert-wrapper a {
		flex-basis: calc(33% - 20px);
		max-width: calc(33% - 20px);
	}	
}

@media (width <= 479px) {
	.about--text__cert-wrapper a {
		flex-basis: calc(50% - 20px);
		max-width: calc(50% - 20px);
	}	
}

.about--text__cert-wrapper a:hover {
	@apply --transitionOpacityEnd;	
}

.about--text__cert-wrapper a img {
	width: 100%;
	height: auto;
}

@media (width >= 992px) {
	.fancybox-close {
		top: 0;
		right: -42px;
	}
}

@media (width <= 991px) {
	.fancybox-close {
		top: 10px;
		right: 7px;
		opacity: 1;
	}	
}

.fancybox-close  {
	background-image: none;
	opacity: 0.6;
	border-radius: 50%;
	border: 1px solid white;
	width: 25px;
	height: 25px;
	transform: rotate(0deg);
	transition: opacity .3s, transform .3s;
}

.fancybox-close:hover,
.fancybox-close:focus {
	transform: rotate(180deg);
	opacity: 1;
}

.fancybox-close::before,
.fancybox-close::after {
	width: 15px;
	height: 1px;
	background-color: white;
	content:"";
	position: absolute;
	top: 11px;
	left: 4px;
}

.fancybox-close::before {
	transform: rotate(45deg);
}

.fancybox-close::after {
	transform: rotate(-45deg);
}


@media (width >= 992px) {
	.fancybox-next {
		right: -50px;
	}

	.fancybox-prev {
		left: -50px;
	}
}