.site-footer__logo {
	width: 287px;
	min-width: 287px;
	background-color: #eff4f8;
	display: flex;
	justify-content: center;
	align-items: center;
	@apply --transitionOpacityStart;
}

.site-footer__logo:hover {
	@apply --transitionOpacityEnd;
}

.site-footer__logo img {
	width: 183px;
	height: auto;
}

@media (width <= 1599px) {
	.site-footer__logo {
		display: none;
	}
}