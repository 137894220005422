.item__text {
	flex-basis: calc(50% - 35px)
}

.item__text .heading--left {
	margin-bottom: 50px;
}

@media (width <= 1199px) {
	.item__text {
		flex-basis: 100%;
		margin-bottom: 50px;
	}
}

.item__text p {
	color: #686e78;
	@apply --m;
	font-size: 16px;
}