.item__slider {
	flex-basis: calc(50% - 35px);
}

.item__slider .carousel-inner img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

	.item__slider .item {
		height: 600px;
	}


.item__slider .carousel-indicators {
	display: grid;
	grid-template-columns: repeat( auto-fit, minmax(45px, 1fr) );
	grid-gap: 5px;
	justify-items: center;
	position: static;
	width: calc(100% - 90px);
	margin-left: auto;
	margin-right: auto;
	margin-top: 25px;
}

.item__slider .carousel-ind-inst {
	display: block;
	width: 45px;
	height: 45px;
	cursor: pointer;
	object-fit: cover;
	object-position: center top;
	transition: opacity .3s;
}

.item__slider .carousel-ind-inst:hover {
	opacity: 0.5;
}

.item__slider .carousel-ind-inst.active {
	opacity: 0.5;
	width: 45px;
	height: 45px;
}

.item__slider .carousel-controls {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	pointer-events: none;
}

.item__slider .carousel-controls a {
	height: 24px;
	width: 15px;
	transition: opacity .3s;
	bottom: 0;
	position: absolute;
	pointer-events: all;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAYCAMAAAAS21dbAAAAQlBMVEUAAABNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktkaHjP4AAAAFXRSTlMAZE2tW1NMO5h+dXFqRO7suY5eWSEPOVGJAAAAYElEQVQY04WQSRKAIAwEAREEwT3//6okBxIol751zyUVJdCTkmQAGRwUhqoGkNCsoHn90rNVSzq+6NFqQFt8vWNHXy9ViYB4DvNzyBwSBcNBU3B9ML8hdWFTjKX3CWLRGyKwCYvcbEUAAAAAAElFTkSuQmCC');
}

.item__slider .carousel-controls a:first-child {
	left: 0;
	right: auto;
	transform: translateY(-50%);
}

.item__slider .carousel-controls a:last-child {
	transform: rotate(180deg) translateY(50%);
	right: 0;
	left: auto;
}

.item__slider .carousel-controls a:hover {
	opacity: 0.7;
}

@media (width <= 1199px) {
	.item__slider {
		flex-basis: 100%;
		margin-bottom: 40px;
	}

	.item__slider .carousel {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (width <= 991px) {

	.item__slider .carousel {
		position: relative;
	}

	.item__slider .carousel-inner {
		max-height: none;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}

	.item__slider .item {
		height: 400px;
	}

	.item__slider .carousel-inner img {
		/* height: auto; */
	}

	.item__slider .carousel-controls {
		width: 100%;
		height: 100%;
		top: 0;
		bottom: auto;
		position: absolute;
		z-index: 1;
	}

	.item__slider .carousel-controls a {
		bottom: 50%;
	}

	.item__slider .carousel-indicators {
		display: none;
	}

	.item__slider  .carousel-controls a:first-child {
		left: -5px;
	}

	.item__slider  .carousel-controls a:last-child {
		right: -5px;
	}
}

/* .slider-thumbs .carousel-ind-inst img {width: 100%;} */