.text-block {
	margin-top: 30px;	
}

.text-block__wrapper {
	padding-bottom: 50px;
	border-bottom: 1px solid #cbced2;
}

@media (width <= 767px) {
	.text-block__wrapper {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.text-block h1 {
	color: #166ed9;
	@apply --c;
	font-size: 24px;
}

.text-block p {
	color: #686e78;
	@apply --m;
	font-size: 20px;
	line-height: normal;
	margin-bottom: 20px;
}

.text-block h1 + p span:first-child {
	display: inline-block;
	padding-right: 15px;
	border-right: 1px solid #686e78;
	margin-right: 15px;
	margin-bottom: 20px;
}

.text-block img {
	max-width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;
}

.text-block h2 {
	color: #27282a;
	@apply --c;
	font-size: 22px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.text-block h3 {
	color: #27282a;
	@apply --c;
	font-size: 20px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.text-block ul,
.about--text ul,
.text-block ol,
.about--text ol {
	padding-left: 0;
	list-style: none;
}

.text-block li,
.about--text li {
	color: #686e78;
	@apply --m;
	font-size: 20px;
	line-height: normal;
	margin-bottom: 15px;
	position: relative;
	padding-left: 40px;
}

.text-block ul li::before,
.about--text ul li::before {
	content: "";
	position: absolute;
	top: 5px;
	left: 2px;
	height: 24px;
	width: 15px;
	height: 12px;
	width: 8px;
	background-repeat: no-repeat;
	background-size: contain;
	display: block;
	transform: rotate(180deg);
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAYCAMAAAAS21dbAAAAQlBMVEUAAABNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktkaHjP4AAAAFXRSTlMAZE2tW1NMO5h+dXFqRO7suY5eWSEPOVGJAAAAYElEQVQY04WQSRKAIAwEAREEwT3//6okBxIol751zyUVJdCTkmQAGRwUhqoGkNCsoHn90rNVSzq+6NFqQFt8vWNHXy9ViYB4DvNzyBwSBcNBU3B9ML8hdWFTjKX3CWLRGyKwCYvcbEUAAAAAAElFTkSuQmCC');
}

.text-block ol,
.about--text ol {
	counter-reset: my-counter;
}

.text-block ol li::before,
.about--text ol li::before {
	position: absolute;
	top: 0;
	left: 0;
	content: counter(my-counter);
	counter-increment: my-counter;
	@apply --cb;
	color: #27282a;
	font-size: 20px;
}

.about--text p > img,
.text-page p > img {
	max-width: 100%;
	height: auto!important;
}

.about--text p > iframe,
.text-page p > iframe {
	max-width: 100%!important;
}