.index--about__wrapper {
	display: flex;
	justify-content: space-between;	
	flex-wrap: wrap;
	/* display: block; */
}

.index--about__wrapper--top {
	background-color: #2585FB;
}

.index--about__text {
	flex-basis: calc(100% - 700px);
	padding-right: 30px;
}

.index--about h1,
.index--about h2 {
	color: white;
	@apply --c;
	font-size: 48px;
	margin-bottom: 30px;
	flex-basis: 100%;
	max-width: 100%;
}

.index--about h1 span,
.index--about h2 span {
	display: block;
	font-size: 32px;
	margin-top: 10px;
}

.index--about__wrapper--top p {
	color: white;
	@apply --m;
	font-size: 18px;
	word-break: break-word;
	flex-basis: 100%;
	max-width: 100%;
}

.index--about__counter,
.index--about__video {
	flex-basis: 670px;
}

.index--about__photo img {
	width: 100%;
	max-width: 675px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	height: auto;
	margin-bottom: -90px;
}

@media (width <= 1599px) {
	.index--about__photo img {
		margin-bottom: 0;
	}
}

.index--about__advantages {
	flex-basis: calc(100% - 670px);
	padding-right: 40px;
}


.index--about__advantages > a {
	display: block;
	height: 60px;
	color: #01102a;
	@apply --c;
	font-size: 21px;
	line-height: 60px;
	margin-top: -30px;
	border-bottom: 1px solid #c6c6c6;
	@apply --transitionOpacityStart;
}

@media (width <= 479px) {
	.index--about__advantages > a {
		height: auto;
		line-height: normal;
		border-bottom: none;
		text-align: center;
		margin-top: 0px;
	}
}

@media (width <= 480px) {
	.item--details__wrapper .index--about__advantages > a {
		font-size: 15px;
		text-align: center;
	}
}

.index--about__advantages > a:hover {
	@apply --transitionOpacityEnd;
}

.index--about__advantages > a svg {
	transform: rotate(180deg);
	height: 16px;
	margin-top: 1px;
	margin-left: 5px;
	display: inline-block;
}

.index--about__advantages__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: 80px;
	padding-bottom: 80px;
}

.index--about__advantages-item {
	flex-basis: calc(25% - 30px);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.index--about__advantages-item img {
	max-width: 100%;
	height: auto;
}

.index--about__advantages-item span {
	display: block;
	width: 100px;
	height: 4px;
	background-color: #2585FB;
	margin-right: auto;
	margin-top: 30px;
	margin-bottom: 30px;
}

.index--about__advantages-item p {
	color: #01102a;
	@apply --m;
	font-size: 18px;
}

.index--about h1 + p a,
.index--about h2 + p a {
	display: inline-block;
	color: #ffce78;
	text-decoration: underline;
	margin-left: 10px;
}

.index--about h1 + p a:hover,
.index--about h2 + p a:hover {
	text-decoration: none;
}

@media (width <= 1599px) {
	.index--about__video {
		margin-top: 29px;
		padding-top: 30px;
		border-top: 1px solid #c6c6c6;
	}
}

@media (width <= 1499px) {
	.index--about__text,
	.index--about__counter,
	.index--about__advantages,
	.index--about__video {
		flex-basis: 50%;
		max-width: 50%;
	}

	.index--about h1,
	.index--about h2 {
		font-size: 42px;
	}

	.index--about h1 span,
	.index--about h2 span {
		font-size: 24px;
	}

	.index--about__advantages__wrapper {
		padding-top: 20px;
		padding-bottom: 30px;
	}

	.index--about__wrapper--top {
		align-items: center;
	}
}

@media (width <= 1199px) {
	.index--about__wrapper {
		flex-wrap: wrap;
	}

	.index--about__text,
	.index--about__counter,
	.index--about__advantages,
	.index--about__video {
		flex-basis: 100%;
		max-width: none;
	}

	.index--about__text {
		padding-right: 0;
		width: 100%;
	}

	.index--about h1,
	.index--about h2 {
		text-align: center;
	}

	.index--about__advantages {
		padding-right: 0;
	}

	.index--about__advantages__wrapper {
		padding-right: 0;
		padding-top: 40px;
		padding-bottom: 0;
	}

	.index--about__video {
		border-top: none;
		padding-top: 0;
		width: 100%;
	}
}

@media (width <= 767px) {

	.index--about h1,
	.index--about h2 {
		font-size: 24px;
	}

	.index--about__advantages-item {
		flex-basis: calc(50% - 30px);
	}
}

@media (width <= 479px) {
	.index--about__advantages-item {
		flex-basis: 100%;
		text-align: center;
		align-items: center;
	}

	.index--about__advantages-item span {
		margin-left: auto;
	}
}

@media (width >= 768px) {
	.index--about__advantages-item:nth-child(even) img {
		order: 1;
	}

	.index--about__advantages-item:nth-child(even) p {
		order: -1;
	}
}

.index--about__counter .counter__heading {
	color: white;
	font-family: "Code Pro LC", sans-serif;
	font-size: 54px;
	line-height: 54px;
	text-transform: uppercase;
	max-width: 440px;
	text-align: center;
	margin: 10px auto 40px;
}

.counter__wrapper {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
}

.counter__numbers {
	flex-basis: 300px;
	max-width: 300px;
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	justify-content: center;
	align-items: center;
}

.counter__number {
	flex-basis: 50%;
	max-width: 50%;
	justify-content: center;
	align-items: center;
	position: relative;
}

.counter__number span {
	color: #ffce78;
	font-family: sans-serif;
	font-weight: bold;
	font-size: 152px;
	line-height: 1;
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.counter__number svg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.counter__text {
	flex-basis: 280px;
	max-width: 280px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 10px;
	padding-right: 10px;
}

.counter__text span {
	color: #2585fb;
	font-family: "Code Pro Bold LC";
	font-size: 16px;
	font-weight: 400;
	line-height: 29px;
	background-color: #ffce78;
	text-transform: uppercase;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 5px;
}

.counter__text p {
	color: white;
	font-family: "Code Pro LC", sans-serif;
	font-size: 27px;
	font-weight: 400;
	line-height: 27px;
	text-transform: uppercase;
	margin-top: 20px;
}

@media (width <= 1199px) {
	.index--about__counter {
		padding-top: 50px;
		padding-bottom: 30px;
	}
}

@media (width <= 767px) {
	.index--about__counter .counter__heading {
		margin-bottom: 20px;
		font-size: 42px;
		line-height: 1;
	}

	.counter__wrapper {
		max-width: 280px;
		flex-direction: column;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
	}

	.counter__numbers,
	.counter__text {
		flex-basis: auto;
		max-width: none;
		width: 100%;
	}

	.counter__numbers {
		margin-bottom: 30px;
	}

	.counter__text {
		align-items: center;
		text-align: center;
	}
}

@media (width <= 479px) {
	.index--about__counter .counter__heading {
		font-size: 36px;
	}
}

.counter__lastYear {
	width: 100%;
	flex-basis: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

.counter__lastYearLine {
	height: 1px;
	background-color: white;
	display: block;
	flex-basis: auto;
	flex-grow: 1;
}

@media (width <= 1199px) {
	.counter__lastYearLine {
		max-width: 100px;
	}
}

.counter__lastYearText {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-basis: 420px;
	flex-wrap: wrap;
	padding: 10px;
	background-color: #FFF;
}

.counter__lastYearText span {
	font-family: "Code Pro Bold LC";
	font-size: 17px;
	margin-left: 5px;
	margin-right: 5px;
	text-align: center;
}

.counter__lastYearText span:first-child {
	color: #2585fb;
}

.counter__lastYearText span:last-child {
	color: #2585fb;
}