.heading-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.heading--left {
	color: #01102a;
	@apply --c;
	font-size: 30px;
	text-align: left;
	text-transform: uppercase;
	position: relative;
	margin-top: 10px;
}

@media (width <= 767px) {
	.heading--left {
		font-size: 22px;
	}	
}

.heading--left::after {
	position: absolute;
	width: 90px;
	height: 4px;
	background-color: #2585FB;
	bottom: -20px;
	left: 0;
	content: "";
}

.heading-wrapper a {
	width: 152px;
	height: 34px;
	background-color: #2585fb;
	border: 1px solid #2585fb;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	@apply --c;
	font-size: 14px;
	text-transform: uppercase;
	transition: background-color .3s, color .3s;
}

.heading-wrapper a:hover {
	background-color: transparent;
	color: #2585fb;
}

@media (width <= 479px) {
	.heading-wrapper a {
		font-size: 12px;
	}
}