.dealersAll__heading {
	margin-top: 40px;
	margin-bottom: 40px;
}

.dealersAll__heading h1 {
	color: #2585fb;
	text-transform: uppercase;
	text-align: center;
	font-size: 30px;
	font-family: "Code Pro LC", sans-serif;
	margin-top: 0;
	margin-bottom: 0;
}

@media (width <= 767px) {
	.dealersAll__heading h1 {
		font-size: 22px;
	}	
}