.catalog--filter__wrapper form {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-top: 20px;
}

.catalog--filter__item {
	flex-basis: auto;
	flex-grow: 1;
	position: relative;
}

.catalog--filter__item::after {
	position: absolute;
	width: 30px;
	height: 48px;
	top: 1px;
	right: 10px;
	content: "";
	background-image: url(../img/sidebar.png);
	background-color: #efefef;
	background-size: 7px 12px;
	background-repeat: no-repeat;
	background-position: center;
	transform: rotate(90deg);
	pointer-events: none;
}

.catalog--filter__item:not(:last-child) {
	margin-right: 12px;
}

.catalog--filter__item select {
	width: 100%;
	height: 50px;
	background-color: #efefef;
	border-color: #efefef;
	border-radius: 2px;
	color: #265186;
	@apply --m;
	font-size: 20px;
	padding-left: 20px;
	padding-right: 31px;
	box-shadow: none;
}

@media (width <= 1599px) {

	.catalog--filter__wrapper form {
		flex-wrap: wrap;
		width: calc(100% + 20px);
		margin-left: -10px;
		margin-right: -10px;
	}

	.catalog--filter__item {
		flex-basis: 33%;
		padding-left: 10px;
		padding-right: 10px;
	}

	.catalog--filter__item:not(:last-child) {
		margin-right: 0;
	}

	.catalog--filter__item::after {
		right: 20px;
	}
}

@media (width <= 991px) {
	.catalog--filter__item {
		flex-basis: 50%;
	}
}

@media (width <= 641px) {
	.catalog--filter__item {
		flex-basis: 100%;
	}
}