.catalog--text__top {
	background-color: #DFE4E8;
	display: flex;
	justify-content: space-between;
}

.catalog--text__text {
	width: calc(100% - 720px);
}

.catalog--text__text img {
	max-width: 100%;
	height: auto!important;
}

.catalog--text__text:only-child {
	width: 100%;
}

@media (width >= 1200px) {
	.catalog--text__text:only-child {
		column-count: 2;
		column-gap: 30px;
		/* column-fill: auto; */
	}
}

@media (width <= 1599px) {
	.catalog--text__text {
		width: 100%;
	}
}

.catalog--text__top h2 {
	margin-bottom: 50px;
	word-break: break-word;
}

.catalog--text__top p,
.catalog--text__top ul,
.catalog--text__top ol {
	color: #686e78;
	@apply --m;
	font-size: 16px;
}

.catalog--text__bottom {
	background-color: #FFF;
}

.catalog--text__bottom h2 {
	font-size: 21px;
}

.catalog--text__bottom .heading--left::after {
	bottom: -10px;
}

.catalog--text__bottom-wrapper {
	display: flex;
	justify-content: space-between;
	min-height: 500px;
	align-items: flex-end;
}

@media (width <= 1599px) {
	.catalog--text__bottom-wrapper {
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.catalog--text__bottom h2 {
		margin-bottom: 50px;
	}
}

@media (width <= 991px) {
	.catalog--text__bottom-wrapper {
		position: relative;
	}
}