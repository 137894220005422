.slider-thumbs {
	margin-top: 40px;
	width: calc(100% - 720px);
	position: relative;
}

@media (width <= 1599px) {
	.slider-thumbs {
		width: 1170px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}


@media (width <= 1199px) {
	.slider-thumbs {
		width: 90%;
	}
}

@media (width <= 991px) {
	.slider-thumbs {
		width: 100%;
		top: 40%;
		position: absolute;
		z-index: 1;
	}

	.slider-thumbs .carousel-indicators {
		display: none;
	}

	.slider-thumbs .carousel-controls a:first-child {
		left: -5px;
	}

	.slider-thumbs .carousel-controls a:last-child {
		right: -5px;
	}
}

.slider-thumbs .carousel-indicators {
	display: flex;
	justify-content: space-between;
	position: static;
	width: calc(100% - 90px);
	margin-left: auto;
	margin-right: auto;
	max-height: 100px;
}

.slider-thumbs .carousel-ind-inst {
	flex-basis: auto;
	flex-grow: 1;
	padding-right: 5px;
	padding-left: 5px;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
}

.slider-thumbs .carousel-ind-inst.active {
	height: 100px;
	width: auto;
	opacity: 0.5;
}

.slider-thumbs .carousel-ind-inst img {
	max-width: 100%;
	height: auto;
	max-height: 100%;
	cursor: pointer;
	object-fit: cover;
	object-position: center;
	@apply --transitionOpacityStart;
}

.slider-thumbs .carousel-ind-inst img:hover {
	@apply --transitionOpacityEnd;	
}

.slider-thumbs .carousel-controls {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	pointer-events: none;
}

.slider-thumbs .carousel-controls a {
	height: 24px;
	width: 15px;
	transition: opacity .3s;
	top: 50%;
	position: absolute;
	pointer-events: all;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAYCAMAAAAS21dbAAAAQlBMVEUAAABNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktkaHjP4AAAAFXRSTlMAZE2tW1NMO5h+dXFqRO7suY5eWSEPOVGJAAAAYElEQVQY04WQSRKAIAwEAREEwT3//6okBxIol751zyUVJdCTkmQAGRwUhqoGkNCsoHn90rNVSzq+6NFqQFt8vWNHXy9ViYB4DvNzyBwSBcNBU3B9ML8hdWFTjKX3CWLRGyKwCYvcbEUAAAAAAElFTkSuQmCC');
}

.slider-thumbs .carousel-controls a:first-child {
	left: 0;
	right: auto;
	transform: translateY(-50%);
}

.slider-thumbs .carousel-controls a:last-child {
	transform: rotate(180deg) translateY(50%);
	right: 0;
	left: auto;
}

.slider-thumbs .carousel-controls a:hover {
	opacity: 0.7;
}