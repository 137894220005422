.youtubeBanner {
	margin-top: 40px;
	margin-bottom: 40px;
	border: 5px solid #2585fb;
	display: flex;
	width: 340px;
	/* width: calc(100% - 85px); */
	height: auto;
	position: relative;
	margin-right: 85px;
	font-family: "Code Pro LC", sans-serif;
}

.youtubeBanner:last-child {
	margin-bottom: 0;
}

.youtubeBanner__img {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.youtubeBanner__img::after {
	position: absolute;
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #2585FB;
	opacity: 0.3;
	transition: opacity .3s;
	will-change: opacity;
}

.youtubeBanner:hover .youtubeBanner__img::after {
	opacity: 0;
}

.youtubeBanner__img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.youtubeBanner__content {
	position: relative;
	width: 100%;
	max-width: 250px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 70px;
	padding-bottom: 70px;
}

.youtubeBanner__text1 {
	color: #ffffff;
	font-size: 22px;
	font-weight: 400;
	line-height: 30px;
	text-transform: uppercase;
	display: block;
	margin-top: 0;
	margin-bottom: 10px;
	text-align: center;
}

.youtubeBanner__text2 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 192px;
	margin-top: 20px;
	margin-bottom: 15px;
}

.youtubeBanner__text2 i {
	display: block;
	width: 5px;
	height: 1px;
	background-color: #FFF;
	flex-grow: 1;
}

.youtubeBanner__text2 b {
	color: #ffffff;
	font-size: 18px;
	font-weight: 400;
	line-height: 25px;
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 10px;
	padding-right: 10px;
}

.youtubeBanner__text3 {
	display: block;
	margin-top: 0;
	margin-bottom: 25px;
	color: #ffffff;
	font-size: 26px;
	font-weight: 400;
	line-height: 40px;
	text-align: center;
}

.youtubeBanner__text4 {
	background-color: #2586fb;
	padding: 5px 10px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	text-align: center;
}

.youtubeBanner__text4 span {
	color: #ffffff;
	font-size: 17px;
	font-weight: 400;
	line-height: 23px;
	text-transform: uppercase;
}

.youtubeBanner__text4 span:not(:last-child) {
	padding-right: 10px;
	margin-right: 7px;
	position: relative;
}

.youtubeBanner__text4 span:not(:last-child)::after {
	content: "";
	position: absolute;
	top: 3px;
	right: 0;
	width: 2px;
	height: 15px;
	background-color: #FFF;
}