.thanksPage {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	position: relative;
	display: flex;
}

.thanksPage > picture,
.thanksPage > picture > img {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-position: center;
	object-fit: cover;
}

.thanksPage__content {
	z-index: 1;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.thanksPage__logo {
	max-width: 100%;
	padding: 40px;
	transition: opacity .3s;
}

.thanksPage__logo:hover {
	opacity: 0.7;
}

.thanksPage__logo img {
	width: 100%;
	height: auto;
}

.thanksPage__heading {
	margin-top: 30px;
	margin-bottom: 30px;
	padding-left: 15px;
	padding-right: 15px;
	color: #ffffff;
	font-family: "Myriad Pro", sans-serif;
	font-size: 30px;
	font-weight: 400;
	text-align: center;
}

@media (width <= 767px) {
	.thanksPage__heading {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 24px;
	}
}

.thanksPage__subheading,
.thanksPage__socialText {
	font-family: "Myriad Pro", sans-serif;
	font-size: 21px;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	margin-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
}

@media (width <= 767px) {
	.thanksPage__subheading,
	.thanksPage__socialText {
		font-size: 16px;
	}
}

.thanksPage__subheading {
	color: #ffffff;
}

.thanksPage__socialText {
	color: #dbf9ff;
}

.thanksPage__social {
	margin-top: 50px;
	margin-bottom: 50px;
}

@media (width <= 767px) {
	.thanksPage__social {
		margin-top: 25px;
		margin-bottom: 25px;
	}
}

.thanksPage__social .site-footer__social a {
	background-color: transparent;
	border: 1px solid #ffffff;
	width: 70px;
	height: 70px;
}

@media (width <= 767px) {
	.thanksPage__social .site-footer__social a {
		width: 35px;
		height: 35px;
	}
}

.thanksPage__social .site-footer__social a:hover {
	background-color: #ffffff;
}

.thanksPage__social .site-footer__social svg {
	width: 35px;
	height: 35px;
	object-fit: contain;
}

@media (width <= 767px) {
	.thanksPage__social .site-footer__social svg {
		width: 20px;
		height: 20px;
	}
}

.thanksPage__social .site-footer__social .iconMainColor {
	fill: #ffffff;
}

.thanksPage__social .site-footer__social a:hover .iconMainColor {
	fill: #2585fb;
}

.thanksPage__goHomepage {
	color: #ffecdb;
	font-family: "Myriad Pro", sans-serif;
	font-size: 21px;
	font-weight: 400;
	line-height: 2;
	text-align: center;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 30px;
	transition: border-bottom-color .3s;
	border-bottom: 1px solid transparent;
}

.thanksPage__goHomepage:hover {
	color: #ffecdb;
	border-bottom-color: currentColor;
}