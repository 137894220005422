.pluso__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	padding-top: 25px;
	padding-bottom: 35px;
}

.pluso__wrapper .pluso {
	z-index: 0;
}

.pluso__wrapper p {
	color: #828486;
	@apply --c;
	font-size: 21px;
	padding-right: 25px;
	padding-bottom: 0;
	margin-bottom: 0;
}

@media (width <= 991px) {
	.pluso__wrapper {
		justify-content: center;
	}

	.pluso__wrapper p,
	.pluso__wrapper .pluso {
		flex-basis: 100%;
		text-align: center;
	}	

	.pluso__wrapper p {
		padding-right: 0;
	}
}