@media (max-width: 991px) {
	.responsive-only .header-top-menu--responsive {
		background-color: rgba(6, 29, 58, .7);
	}

	.responsive-only .header-top-menu--responsive ul {
		padding: 25px 25px 0;
	}

	.responsive-only .header-top-menu--responsive ul a {
		padding-top: 5px;
		padding-bottom: 5px;
		display: inline-block;
	}

	.responsive-only .header-top-menu--responsive ul li:hover a {
		color: #ffe5bb;
	}
}
