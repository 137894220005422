.item__main-text {
	flex-basis: calc(50% - 35px);
}

.item__main-text-heading p {
	color: #0e2653;
	@apply --m;
	font-size: 22px;
}

.item__main-text > p {
	color: #7d838e;
	@apply --m;
	font-size: 20px;
}

.item__main-text-doc {
	margin-top: 30px;
	margin-bottom: 30px;
}

.item__main-text-doc a {
	display: inline-block;
	color: #3677c9;
	@apply --c;
	font-size: 17px;
	height: 50px;
	border: 1px solid #3677c9;
	border-radius: 2px;
	padding: 15px 40px;
	margin-right: 30px;
	line-height: normal;
	margin-top: 5px;
	margin-bottom: 5px;
}

.item__main-text-doc .is-pdf {
	padding-left: 60px;
	position: relative;
}

.item__main-text-doc .is-pdf::after {
	position: absolute;
	content: "";
	top: 12px;
	left: 21px;
	width: 21px;
	height: 23px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAXCAMAAADTAfSvAAAAb1BMVEUAAAA2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8kd9SKPAAAAJXRSTlMA4PGwQA+gUMEwIOTVFQTQuH9x6r1EOs/MppWFZWBYKRwK/mhJrO+GPgAAAKZJREFUGNOd0UkOgzAMQFFDbKYMDWGeoe39z9iiRBAh1EX/8i0sy4Y9xULXs4ejqgRXkAyHxtGh1JobBSnGG4U1mG8UMv6+6uNbEQIQ0dyRTclsj4PmwZlQbg4HryR1GkCGi8J6k4jkaR61sRjKiZnVV9ZUDJpRMPDVSNKLmAz4GqewRX1dV1Z/7CA0O+PklBAx12iTYPVyh38VS+WjLOyPu9CveH0AnD4IUx2w2sEAAAAASUVORK5CYII=');
}

.item__main-text ul {
	padding-left: 0;
	list-style: none;
}

.item__main-text li {
	color: #7d838e;
	@apply --m;
	font-size: 20px;
	margin-bottom: 15px;
	position: relative;
	padding-left: 50px;
}

.item__main-text li::before {
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	width: 28px;
	height: 28px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAjVBMVEUAAADxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEzxtEw1KHetAAAALnRSTlMAB/t/29ISAzfNxoT351ZCNCzz7LKmkIkc4qBqUCEXm3BiYFu9rZd3SD4nwbp0kyYJrgAAATtJREFUKM9lUoeygzAMywRC2bMt3fNN/f/nNaxAwHfcOdZhSbaJCTdp6lfFoxNZxbbKMYR/cy2I1gxZ2cSJd+A+IOIZdpQIGzq+0hLYGOwnROnMG8Uh9kPq7LBZKpBjqUC11hei4z1A0bX6b4i2qpDY9a9LW97jSoiHPxvzgKhVCUVIjbttWSI4tomPrf4cC9wA7y7hukG+s7CEQbmkF8oJ1KIpS0fuisCfgxzgYw+8SCZ6X9lDc6cMko6OUBPJulcDyJOrwIzpizZ67udEn4Co5usokGpR5/4QzoDGzSSdQNugeXAyDuFN0romb7O7S86ntWSZ07IJRGQZ9Bf/vVsWxAvMLfEYriwCu1mYU0CaiUcBnsfpt3sIf3a9qQKKe4tTjwuwmlokVwEgCHMArExXAj1eyJ3w97dpvx8U1xzpHWCYKQAAAABJRU5ErkJggg==');
}

.item__main-text-buttons {
	margin-top: 30px;
	margin-bottom: 30px;
}

.item__main-text-buttons .send-request {
	display: inline-block;
	padding: 12px 30px;
	line-height: normal;
	background-color: #2585fb;
	border: 1px solid #2585fb;
	border-radius: 2px;
	color: white;
	@apply --c;
	font-size: 14px;
	text-transform: uppercase;
	transition: background-color .3s, border-color .3s, color .3s;
	margin-right: 30px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.item__main-text-buttons .send-request:hover {
	background-color: transparent;
	color: #2585fb;
}

.item__main-text-buttons .terms-of-leasing {
	display: inline-block;
	padding: 12px 30px 12px 60px;
	line-height: normal;
	position: relative;
	background-color: transparent;
	border: 1px solid #3677c9;
	border-radius: 2px;
	color:#3677c9;
	@apply --c;
	font-size: 14px;
	text-transform: uppercase;
	transition: background-color .3s, border-color .3s, color .3s;
}

.item__main-text-buttons .terms-of-leasing:hover {
	background-color: #3677c9;
	color: white;
}

.item__main-text-buttons .terms-of-leasing::after {
	position: absolute;
	content: "";
	top: 5px;
	left: 15px;
	width: 28px;
	height: 28px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAeFBMVEUAAAA2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8n/NzRNAAAAJ3RSTlMA9Ab6ZxTtnpdZqYdJ08qvGA6+uY59byvZgHp0YDo15+HDPh6jUELgjJQ4AAABUElEQVQoz41S2ZLDIAzDuQi576tJkyZt9f9/uIQFmtmHnfoB0MjWyBrYV1UKnhJAKRflH6oICQOfPG/iAygsrpxH1PQG9A2RZynniUj3HqXSifB0NJlDN742IFe0h5ypiiF+H3dUoqNGvQXi81rkpbsCqfWozcgiz2ww8jPaJESnjQzZqSWssxCg0QCBO2uklrV93y8gaFgWWTgSUPkWRhmjyYAO4T4HuSUnYrBhJK700rqf2HAhWRoydvuQHdgaW7TJJ08tjFdWVwb4mOU0t+SjljrG3xs9K+F9em+scPXass1hPXZDtm4hY6FD55OeeS2aKyg8x4OHo2CznrFkOp8q8NV+eCq8IHrdDPmGMBHXqssL4Ea+sdNp/Rets9I6HHW9fbWIrqPG1vb6f7WbFEhG6d3UnrugrKpSOpl2gIvk+quTkec5n5JSueM0s3/rB/NXFlQLYtIFAAAAAElFTkSuQmCC');
}

.item__main-text-buttons .terms-of-leasing:hover::after {
	filter: invert(1) grayscale(1) brightness(2);
}

.item__main-text-number {
	border: 1px solid #b0b4b8;
	padding: 20px;
}

.item__main-text-number p {
	@apply --m;
	font-size: 18px;
}

.item__main-text-number p a {
	color: #194ca5;	
}

.item__main-text-number form {
	display: flex;
	position: relative;
}

.item__main-text-number select,
.item__main-text-number input,
.item__main-text-number button {
	padding-right: 10px;
	padding-left: 10px;
	height: 50px;
	border-color: #efefef;
	border-radius: 2px;
	background-color: #efefef;
	box-shadow: none;
	color: #265186;
	font-size: 20px;
	font-family: "Myriad Pro", sans-serif;
}

.item__main-text-number select {
	min-width: 100px;
	max-width: 120px;
	padding-left: 10px;
	padding-right: 10px;
}

.item__main-text-number input {
	margin-left: 10px;
	margin-right: 10px;
	width: auto;
	flex-grow: 1;
}

.item__main-text-number button {
	border: none;
	color: #2585fb;
	@apply --c;
	font-size: 17px;
}

@media (width <= 1199px) {
	.item__main-text {
		flex-basis: 100%;
	}	

	.item__main-text-number {
		max-width: 550px;
	}
}

@media (width <= 767px) {
	.item__main-text-number form {
		flex-wrap: wrap;
	}

	.item__main-text-number form::after {
		display: none;
	}

	.item__main-text-number select {
		width: 120px;
		margin-right: 10px;
	}

	.item__main-text-number input {
		width: calc(100% - 130px);
		margin-left: 0;
		margin-right: 0;
	}

	.item__main-text-number button {
		margin-top: 10px;
		margin-left: auto;
		margin-right: auto;
	}
}


@media (width <= 479px) {
	.item__main-text-doc .is-pdf {
		padding-left: 20px;
		width: 100%;
		max-width: 280px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		display: block;
	}

	.item__main-text-doc .is-pdf::after {
		display: none;
	}

	.item__main-text .item__main-text-buttons a {
		padding-left: 20px;
		width: 100%;
		max-width: 280px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		display: block;
	}
	
}