.dealersAll__subheading {
	margin-top: 40px;
	margin-bottom: 40px;
}

.item__slider--dealersAll .dealersAll__subheading {
	margin-top: 0;
	margin-bottom: 80px;
}

.dealersAll__subheading span {
	color: #01102a;
	text-transform: uppercase;
	text-align: center;
	font-size: 24px;
	font-family: "Myriad Pro Bold", sans-serif;
	margin-top: 0;
	margin-bottom: 0;
	position: relative;
	display: block;
}

.dealersAll__subheading span::after {
	position: absolute;
	bottom: -20px;
	left: calc(50% - 45px);
	width: 90px;
	height: 4px;
	background-color: #2585FB;
	content: "";
}

@media (width <= 767px) {
	.dealersAll__subheading span {
		font-size: 18px;
	}	
}