.dealer--content {
	border-bottom: 1px solid #dfe4e8;
	margin-bottom: -1px;
}

.dealer--content h1 {
	color: #01102a;
	@apply --c;
	font-size: 33px;
	position: relative;
	text-align: center;
	margin-bottom: 50px;
	margin-top: 30px;
}

.dealer--content h1::after {
	position: absolute;
	bottom: -20px;
	left: 50%;
	width: 90px;
	height: 4px;
	background-color: #2585FB;
	content: "";
	transform: translateX(-50%);
}

.dealer--content .all-dealers-link {
	margin: 60px auto 70px;
	width: 152px;
	height: 34px;
	background-color: #2585fb;
	border: 1px solid #2585fb;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	@apply --c;
	font-size: 14px;
	text-transform: uppercase;
	transition: background-color .3s, color .3s;
}

.dealer--content .all-dealers-link:hover {
	background-color: transparent;
	color: #2585fb;
}