.site-header__search-form {
	position: relative;
	width: 40px;
	height: 50px;
	cursor: pointer;
}

.site-header__search-input {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	padding-right: 20px;
	padding-left: 20px;
	border: none;
	color: #454545;
	font-family: "Myriad Pro";
	font-size: 18px;
	transition: width 0.3s;
	@apply --m;
}

.is-active .site-header__search-input {
	display: block;
	width: 100%;
	height: 50px;
}

.navbar-nav .menu-search-form,
.is-active.site-header__search-form {
	position: static;
}

.site-header__search-input:focus {
	outline-color: transparent;
	caret-color: #e4b75c;
}

.site-header__search-button {
	display: flex;
	position: absolute;
	top: 11px;
	right: 10px;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	padding: 0;
	border: none;
	background-color: transparent;
	pointer-events: none;
}

.is-active .site-header__search-button {
	pointer-events: all;
}

.site-header__search-button:focus {
	outline-color: transparent;
}

#siteHeaderSearch {
	width: 18px;
	height: 23px;
	transition: transform 0.3s;
}

.site-header__search-form:hover #siteHeaderSearch {
	transform: scale(1.25);
}

.site-header__search-icon {
	transition: fill 0.3s;
	fill: #e4b75c;
}

.site-header__search-form:hover .site-header__search-icon {
	fill: white;
}

.is-active .site-header__search-icon,
.is-active.site-header__search-form:hover .site-header__search-icon {
	fill: #2585fb;
}
