.header-bottom-right {
	flex-basis: 360px;
	padding-right: 100px;
}

@media (width <= 1599px) {
	.header-bottom-right {
		padding-right: 80px;
	}	
}

@media (width <= 1199px) {
	.header-bottom-right {
		padding-right: 45px;
	}	
}

.is-fixed .header-bottom-right {
	padding-right: 0;
	flex-basis: auto;
}

@media (width <= 991px) {
	.header-bottom-right {
		display: none;
	}
}