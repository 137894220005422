.map {
	width: 100%;
	height: 700px;
	z-index: 0;
	position: relative;
}

@media (width >= 1200px) {
	.map::before {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.1);
		transition: background-color .3s;
		pointer-events: none;
	}

	.is-active.map::before {
		background-color: rgba(0,0,0,0);
	}	
}

@media (width <= 1199px) {
	.map {
		height: 400px;
	}
}