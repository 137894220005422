.item__accordion {
	margin-top: 40px;
	margin-bottom: 80px;
	flex-basis: calc(50% - 35px)
}

@media (width <= 1199px) {
	.item__accordion {
		flex-basis: 100%;
		width: 100%;
	}
}

.item__accordion .nav-tabs li a,
.item__accordion .nav-tabs li.active a {
	text-decoration: none;
	color: #2585fb;
	@apply --c;
	font-size: 14px;
	text-transform: uppercase;
	margin-right: 0;
	border-radius: 0;
	border-color: transparent;
	padding: 15px 20px;
}

.item__accordion .nav-tabs li:not(:last-child) a {
	border-right: none;
}

.item__accordion .nav-tabs li.active a {
	font-weight: bold;
	color: #01102a;
	background-color: #EFEFEF;
}

.item__accordion .tab-content .tab-pane {
	padding: 40px 35px;
	overflow-y: auto;
	max-height: 800px;
	background-color: #FFF;
}

.item__accordion .tab-content .active {
	background-color: #EFEFEF;
}

.item__accordion .tab-content h3 {
	color: #32363c;
	@apply --mb;
	font-size: 18px;
	margin-top: 0;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.item__accordion .tab-content ul {
	margin-bottom: 20px;
	list-style: none;
}

.item__accordion .tab-content p,
.item__accordion .tab-content li {
	color: #32363c;
	@apply --m;
	font-size: 16px;
}

.item__accordion .tab-content li {
	position: relative;
}

.item__accordion .tab-content li::before {
	content: "";
	width: 6px;
	height: 6px;
	background-color: #2585fb;
	top: 15px;
	left: -20px;
	position: relative;
	display: block;
	border-radius: 50%;
}

.item__accordion .nav-tabs {
	border-bottom: none;
}

.item__accordion .tab-pane {
	overflow-x: auto;
}

@media (width <= 767px) {

	.item__accordion .tab-content {
		padding-left: 0;
		padding-right: 0;
	}

	.item__accordion .tab-content .tab-pane {
		padding: 30px;
	}

	.item__accordion .nav-tabs li:not(:last-child) a {
		border: 1px solid #e5e5e5;
		margin-right: -1px;
	}
}