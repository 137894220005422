.section-wrapper--standard {
	padding: 30px 100px;
}

@media (max-width: 1599px) {
	.section-wrapper--standard {
		padding-left: 30px;
		padding-right: 30px;
	}
}

/* @media (max-width: 1199px) {
	.section-wrapper--standard {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (max-width: 991px) {
	.section-wrapper--standard {
		padding-left: 30px;
		padding-right: 30px;
	}
} */