@media (width <= 991px) {
	.header-top-menu {
		display: none;
	}
}

.header-top-menu nav {
	background-image: url(../img/header-top-menu.png);
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
}

.header-top-menu ul {
	width: 890px;
	display: flex;
	padding-left: 0;
	list-style: none;
	min-height: 50px;
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	justify-content: space-between;
	align-items: center;
}

.header-top-menu a {
	color: white;
	@apply --c;
	font-size: 14px;
	text-transform: uppercase;
	border-bottom: .5px solid transparent;
	transition: border-color .3s;
}

.header-top-menu a:hover,
.header-top-menu .active a {
	border-color: white;
}

.is-fixed .header-top-menu {
	display: none;
}

@media (width <= 991px) {
	.responsive-only .header-top-menu {
		display: block;
		background-color: rgba(6, 29, 58, .9);
	}

	.responsive-only .header-top-menu .container {
		width: 100%;
	}

	.responsive-only .header-top-menu nav {
		background-image: none;
	}

	.responsive-only .header-top-menu ul {
		width: 100%;
		display: block;
	}

	.responsive-only .header-top-menu a {
		font-size: 21px;
		padding-bottom: 10px;
		padding-top: 10px;
		border-bottom: none;
	}
}
