.blog-inner--content {
	margin-top: 30px;	
}

.blog-inner--content__wrapper {
	padding-bottom: 50px;
	border-bottom: 1px solid #cbced2;
}

@media (width <= 767px) {
	.blog-inner--content__wrapper,
	#disqus_thread {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.blog-inner--content h1 {
	color: #166ed9;
	@apply --c;
	font-size: 24px;
}

@media (width <= 479px) {
	.blog-inner--content h1 {
		font-size: 22px;
	}	
}

.blog-inner--content p {
	color: #686e78;
	@apply --m;
	font-size: 20px;
	line-height: normal;
	margin-bottom: 20px;
}

.blog-inner--content h1 + p span:first-child {
	display: inline-block;
	padding-right: 15px;
	border-right: 1px solid #686e78;
	margin-right: 15px;
	margin-bottom: 20px;
}

.blog-inner--content img {
	height: auto!important;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;	
	max-width: 70%;
	margin: 40px auto 0;
	display: block;
}

.blog-inner--content h2 {
	color: #27282a;
	@apply --c;
	font-size: 22px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.blog-inner--content h3 {
	color: #27282a;
	@apply --c;
	font-size: 20px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.blog-inner--content ul,
.blog-inner--content ol {
	padding-left: 0;
	list-style: none;
}

.blog-inner--content li {
	color: #686e78;
	@apply --m;
	font-size: 20px;
	line-height: normal;
	margin-bottom: 30px;
	position: relative;
	padding-left: 40px;
}

.blog-inner--content ul li::before {
	content: "";
	position: absolute;
	top: 5px;
	left: 1px;
	height: 12px;
	width: 8px;
	background-repeat: no-repeat;
	background-size: contain;
	display: block;
	transform: rotate(180deg);
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAYCAMAAAAS21dbAAAAQlBMVEUAAABNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktlNktkaHjP4AAAAFXRSTlMAZE2tW1NMO5h+dXFqRO7suY5eWSEPOVGJAAAAYElEQVQY04WQSRKAIAwEAREEwT3//6okBxIol751zyUVJdCTkmQAGRwUhqoGkNCsoHn90rNVSzq+6NFqQFt8vWNHXy9ViYB4DvNzyBwSBcNBU3B9ML8hdWFTjKX3CWLRGyKwCYvcbEUAAAAAAElFTkSuQmCC');
}

.blog-inner--content ol {
	counter-reset: my-counter;
}

.blog-inner--content ol li::before {
	position: absolute;
	top: 0;
	left: 0;
	content: counter(my-counter);
	counter-increment: my-counter;
	@apply --cb;
	color: #27282a;
	font-size: 20px;
}