.about--text__photo-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: 40px;
}

.about__photo-card {
	flex-basis: calc(33% - 25px);
	max-width: 310px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;
}

.about__photo-card:not(:nth-child(3n)) {
	margin-right: 25px;
}

@media (width <= 1499px) {
	.about__photo-card {
		flex-basis: calc(50% - 25px);
	}

	.about__photo-card:not(:nth-child(3n)) {
		margin-right: 0;
	}

	.about__photo-card:not(:nth-child(2n)) {
		margin-right: 25px;
	}
}


	@media (width <= 991px) {
		.about__photo-card {
			flex-basis: 100%;
			margin-right: 0;
			max-width: 100%;
		}

		.about__photo-card-img {
			max-width: 310px;
			margin-left: auto;
			margin-right: auto;
		}

		.about__photo-card p {
			flex-basis: 100%;
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}

		.about__photo-card:not(:nth-child(2n)),
		.about__photo-card:not(:nth-child(3n)) {
			margin-right: 0;
		}
	}

	.about__photo-card .about__photo-card-img {
		width: 100%;
		margin-bottom: 30px;
	}

	.about__photo-card img {
		width: 100%!important;
		height: auto!important;
	}

	.about__photo-card p {
		@apply --m;
		color: #2b2a2a;
		font-size: 16px;
	}

	.about__photo-card b {
		@apply --mb;
		color: #2585fb;
		font-size: 24px;	
	}

	.about__photo-card span {
		display: block;
		color: #686e78;
		font-size: 18px;
	}