.site-footer__copy {
	flex-basis: calc(100% - 422px);
	margin-left: 30px;
	padding: 15px 30px 15px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #bac0c9;
}

.site-footer__dormash {
	color: #7d838e;
	@apply --m;
	font-size: 16px;
}

.site-footer__copy .site-footer__zwebra {
	color: #7b7979;
	@apply --m;
	font-size: 12px;
	text-align: right;
	display: flex;
	align-items: center;
	margin-bottom: 0;
	@apply --transitionOpacityStart;
}

.site-footer__zwebra:hover {
	@apply --transitionOpacityEnd;	
}

.site-footer__zwebra span {
	display: block;
	line-height: 1;
}

.site-footer__zwebra > span:first-child {
	margin-right: 10px;
	margin-top: 2px;
}

.site-footer__zwebra > span:last-child {
	width: 81px;
	height: 24px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../img/zwebra-logo.png);
}


@media (width <= 1599px) {
	.site-footer__copy {
		flex-basis: calc(100% - 317px);
	}
}

@media (width <= 1499px) {
	.site-footer__copy {
		flex-basis: 100%;
		padding-left: 30px;
		margin-left: 0;
	}
}

@media (width <= 1199px) {
	.site-footer__copy {
		flex-wrap: wrap;
	}
}

@media (width <= 641px) {
	.site-footer__copy {
		flex-wrap: wrap;
	}

	.site-footer__dormash,
	.site-footer__zwebra {
		flex-basis: 100%;
	}

	.site-footer__dormash {
		text-align: center;
	}

	.site-footer__zwebra {
		justify-content: center;
	}
}

@media (width <= 479px) {
	.site-footer__copy {
		padding-left: 0;
		padding-right: 0;
	}
}