@media (width >= 480px) {}
@media (width >= 768px) {}
@media (width >= 992px) {}
@media (width >= 1200px) {}
@media (width >= 1355px) {}
@media (width >= 1440px) {}
@media (width >= 1600px) {}
@media (width <= 1599px) {}
@media (width <= 1499px) {}
@media (width <= 1199px) {}
@media (width <= 991px) {}
@media (width <= 767px) {}
@media (width <= 641px) {}
@media (width <= 479px) {}

body {
	min-width: 320px;
	overflow-x: hidden;
}


a {
	outline-color: transparent!important;
}

a:hover,
a:focus {
	text-decoration: none;
}

@media (width <= 479px) {
	.g-recaptcha {
		transform: scale(0.79);
		transform-origin: 0 0;	
	}	
}

.b-lazy {
	transition: opacity 500ms ease-in-out;
	max-width: 100%;
	opacity: 0;
}
.b-lazy.b-loaded {
	opacity: 1;
}

.site-header__bgi-wrapper {
	background-image: url(../img/header.jpg);
}

.index--about__counter {
	display: none !important;
}

@media (width >= 1200px) {
	.index--about__text {
		max-width: 100% !important;
		flex-basis: 100% !important;
		padding-right: 0 !important;
		column-count: 2;
		column-gap: 30px;
		column-fill: balance;
	}
	.index--about__text h1 {
		margin-top: 0 !important;
	}
}