.blog__excerpt {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 50px;
	border-bottom: 1px solid #cbced2;	
}

.blog__excerpt:not(:last-child) {
	margin-bottom: 50px;
}

.excerpt__photo {
	flex-basis: 50%;
	@apply --transitionOpacityStart;
}

.excerpt__photo:hover {
	@apply --transitionOpacityEnd;
}

.excerpt__photo img {
	width: 100%;
	height: auto;
}

.excerpt__text {
	flex-basis: 50%;
	padding-left: 45px;
	display: flex;
	flex-direction: column;
}

.excerpt__text h2 {
	margin-top: 0;
	margin-bottom: 20px;
	@apply --transitionOpacityStart;
}

.excerpt__text h2:hover {
	@apply --transitionOpacityEnd;
}

.excerpt__text h2 a {
	color: #166ed9;
	@apply --c;
	font-size: 24px;
	line-height: normal;
	text-transform: uppercase;
}

.excerpt__text p {
	color: #686e78;
	@apply --m;
	font-size: 20px;
	line-height: normal;
	margin-bottom: 20px;
}

.excerpt__text h2 + p span:first-child {
	display: inline-block;
	padding-right: 15px;
	border-right: 1px solid #686e78;
	margin-right: 15px;
}

.excerpt__text .excerpt-button {
	color: #3677c9;
	@apply --c;
	font-size: 17px;
	width: 185px;
	height: 50px;
	border: 1px solid #3677c9;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	transition: background-color .3s, color .3s;
	margin-top: auto;
}

.excerpt__text .excerpt-button:hover {
	background-color: #3677c9;
	color: #FFF;
}

.excerpt__text .excerpt-button span:first-child {
	display: block;
	width: 9px;
	height: 15px;
	margin-top: -1px;
	transform: translateX(-20px);
	filter: invert(0) grayscale(0) brightness(1);
	transition: transform .3s .1s, filter .3s;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAPCAMAAAABFhU/AAAARVBMVEUAAAA2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8k2d8nP+744AAAAFnRSTlMA9/LsW+aiUznhl01IPzMwK9q5aGALr7W7UAAAAEpJREFUCNc9zksSgCAMA9BSFATFv7n/UdVJaVdv0WQiUhfhVcRGDQFxco6bEc4bKDt5BRzGBJz8VJT1R/7SRG+cHQptFlOb8KRMvOSpAwDXaZ+jAAAAAElFTkSuQmCC');
}

.excerpt__text .excerpt-button:hover span:first-child {
	transform: translateX(-10px);
	filter: invert(1) grayscale(1) brightness(2);
}

@media (width <= 991px) {
	.excerpt__photo {
		flex-basis: 100%;
	}

	.excerpt__text {
		flex-basis: 100%;
		padding-left: 0;
		margin-top: 40px;
	}
}

@media (width <= 479px) {
	.excerpt__text h2 a {
		font-size: 18px;
	}	
}