.contacts--content__text {
	flex-basis: calc(100% - 680px);
	padding-right: 100px;
}

@media (width <= 1499px) {
	.contacts--content__text {
		flex-basis: 50%;
		padding-right: 0;
	}
}

@media (width <= 991px) {
	.contacts--content__text {
		flex-basis: 100%;
		margin-bottom: 50px;
	}
}

.contacts--content__text h2 {
	color: #01102a;
	@apply --c;
	font-size: 24px;
	text-transform: uppercase;
	margin-bottom: 50px;
}

.contacts--content__text p {
	color: #7d838e;
	@apply --m;
	font-size: 22px;
}

.contacts--content__text .heading-wrapper {
	margin-top: 50px;
}