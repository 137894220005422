.index--clients {

}

.clients-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 50px;
}

.clients-item {
	flex-basis: 25%;
	margin-bottom: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;

}

@media (width >= 1200px) {
	.clients-item {
		will-change: opacity;
		opacity: 0.5;
		transition: opacity .3s;
	}

	.clients-item:hover {
		opacity: 1;
	}
}

.clients-item .img-wrapper,
.clients-item p {
	flex-basis: calc(50% - 25px);
}

.clients-item .img-wrapper {
	height: 115px;
	margin-right: 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.img-divider {
	display: block;
	position: absolute;
	height: 100%;
	width: 1px;
	background-color: #cbcdd2;
	top: 0;
	left: 50%;
}

.clients-item p {
	margin-left: 25px;
	color: #01102a;
	@apply --m;
	font-size: 16px;
}

.clients-item img {
	max-width: 100%;
	height: auto;
	object-fit: contain;
}

@media (width >= 1200px) {
	.clients-item img {
		filter: grayscale(1);
		transition: filter .3s;
	}

	.clients-item:hover img {
		filter: grayscale(0);
	}	
}

@media (width <= 1199px) {
	.clients-wrapper {
		flex-wrap: wrap;
	}

	.img-divider {
		display: none;
	}

	.clients-item {
		flex-basis: 25%;
		flex-wrap: wrap;
	}	

	.clients-item .img-wrapper,
	.clients-item p {
		flex-basis: 100%;
	}

	.clients-item .img-wrapper {
		flex-direction: row;
		justify-content: center;
		margin-right: 0;
	}

	.clients-item p {
		text-align: center;
		margin-left: 0;
	}
}

@media (width <= 767px) {
	.clients-item {
		flex-basis: 50%;
	}	
}

@media (width <= 479px) {
	.clients-item {
		flex-basis: 100%;
	}	
}