.item__photo {
	flex-basis: calc(50% - 35px)
}

.item__photo .heading--left {
	margin-bottom: 50px;
}

@media (width <= 1199px) {
	.item__photo {
		flex-basis: 100%;
	}
}

.item__photo__wrapper {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-left: -5px;
	margin-right: -5px;
}

.item__photo__wrapper a {
	padding: 5px;
	flex-basis: 20%;
	@apply --transitionOpacityStart;
}

.item__photo__wrapper a:hover {
	@apply --transitionOpacityEnd;
}

.item__photo__wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media (width <= 479px) {
	.item__photo__wrapper a {
		flex-basis: 50%;
	}	
}