.disqus-body {margin-bottom: 50px;}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus
{font-size: 30px; color: #337ab7 !important; line-height: 1; font-family: "Code Pro LC", sans-serif; background: none !important;}
.pagination li:first-child span, .pagination li:last-child span {background: none !important; font-size: 30px; font-family: "Code Pro LC", sans-serif; height: auto !important; color: #b1b3b7 !important;}
.pagination li.disabled {display: none !important;}
.alert {text-align: center; position: absolute; z-index: 1000; width: 100%; border-radius: 0px;}
.alert a {color: #a94442 !important; text-decoration: underline; font-weight: bold;}

.breadcrumb.no-content-before > li + li::before {display: none;}
.breadcrumb.no-content-before > li + li {margin-left: 10px; padding-left: 11px; border-left: 1px solid #fff;}
.breadcrumb.no-content-before li a {font-size: 20px; padding: 0px;}
.breadcrumb.no-content-before li.active a {color: #fff;}

.dealer__card-infographic {display: none;}
.catalog--items .card-in-center {margin: 50px auto;}
.card a b {text-align: center; width: 100%; margin-bottom: 0px;}
.product_type {font-size: 18px; color: #555d6b; margin-top: 10px; text-align: center;}
.page-heading h1 {margin-bottom: 0; color: rgb(255, 255, 255); text-align: center; text-transform: uppercase; font-size: 40px; font-family: "Code Pro LC", sans-serif;}

@media (width <= 767px) {
	.page-heading h1 {
		font-size: 22px!important;
	}
}

.page-heading h1,
.page-heading div {
	margin-bottom: 0;
	color: rgb(255, 255, 255);
	text-align: center;
	text-transform: uppercase;
	font-size: 40px;
	font-family: "Code Pro LC", sans-serif;
}

.dealerTable__title {
	padding: 12px 22px;
	max-width: 100%;
	width: 260px;
	border-right: 1px solid #c9d3db;
	background-color: white;
	color: #40464d;
	letter-spacing: 3px;
	font-weight: 400;
	font-size: 16px;
	font-family: "Myriad Pro";
}

.dealerTable__data {
	padding: 12px 22px;
	max-width: calc(100% - 260px);
	width: 100%;
	background-color: white;
	color: #2585fb;
	letter-spacing: 3px;
	font-weight: 400;
	font-size: 16px;
	font-family: "Myriad Pro";
}