.about--text {
	padding-left: 100px;
	flex-basis: calc(100% - 555px);
	width: calc(100% - 555px);
	overflow-x: hidden;
}

@media (width <= 1599px) {
	.about--text {
		flex-basis: calc(100% - 400px);
	}	
}

@media (width <= 991px) {
	.about--text {
		flex-basis: calc(100% - 360px);
		padding-left: 40px;
	}	
}

@media (width <= 767px) {
	.about--text {
		flex-basis: 100%;
		padding-left: 0;
	}	
}

.about--text h2 {
	margin-top: 0;
	margin-bottom: 50px;
}

.about--text p {
	color: #686e78;
	@apply --m;
	font-size: 20px;
}