.catalog--items .card-wrapper {
	padding-top: 0;
	margin-top: -50px;
}

.catalog--items .card {
	margin: 50px 15px;
}

/* .catalog--items .card:last-child {
	margin-right: auto;
	} */

	@media (width <= 641px) {
		.catalog--items .card {
			margin: 25px 15px;
		}
		.catalog--items .card-wrapper {
			margin-top: 0;
		}
	}