.index--novelties .heading--center {
	color: #01102a;
	@apply --c;
	font-size: 40px;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 5px;
	position: relative;
}

@media (width <= 767px) {
	.index--novelties .heading--center {
		font-size: 30px;
		margin-top: 40px;
	}
}

@media (width <= 479px) {
	.index--novelties .heading--center {
		font-size: 26px;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.index--novelties .subheading--center {
	color: #7d838e;
	@apply --m;
	font-size: 22px;
	text-align: center;
	position: relative;
}

.index--novelties .heading--center::after {
	position: absolute;
	width: 90px;
	height: 4px;
	background-color: #2585FB;
	bottom: -15px;
	left: 50%;
	transform: translateX(-50%);
	content: "";
}

@media (width <= 479px) {
	.index--novelties {
		margin-top: 20px;
	}
}