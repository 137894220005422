.contacts--content__form {
	flex-basis: 680px;
	position: relative;
	top: -100px;
}

@media (width <= 1199px) {
	.contacts--content__form {
		top: 0;
	}
}

@media (width <= 1499px) {
	.contacts--content__form {
		flex-basis: 50%;
		padding-left: 50px;
	}
}

@media (width <= 1199px) {
	.contacts--content__form {
		padding-left: 20px;
	}
}

@media (width <= 991px) {
	.contacts--content__form {
		flex-basis: 100%;
		max-width: 100%;
		padding-left: 0;
	}
}

.contacts--content__form-wrapper {
	background-color: #efefef;
	border-radius: 0;
	padding: 50px;
	box-shadow: 0 5px 5px rgba(0,0,0,.3);
	max-width: 640px;
	margin-left: auto;
	margin-right: auto;
}

@media (width <= 767px) {
	.contacts--content__form-wrapper {
		padding: 50px 20px;
	}	
}

.contacts--content__form h2 {
	@apply --c;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 20px;
	text-align: left;
	color: #2585fb;
	font-size: 21px;
}

.contacts--content__form form {
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}

.contacts--content__form .form-group {
	margin-bottom: 25px;
}

.contacts--content__form input,
.contacts--content__form textarea {
	background-color: white;
	border-radius: 0;
	border-color: #efefef;
	width: 100%;
	color: #8e8888;
	@apply --c;
	font-size: 17px;
	text-transform: uppercase;
	margin-left: auto;
	margin-right: auto;
}

.contacts--content__form input:focus,
.contacts--content__form textarea:focus {
	border-color: #2585fb;
}

.contacts--content__form input {
	height: 50px;
}

.contacts--content__form textarea {
	resize: vertical;
	min-height: 100px;
}

.contacts--content__form form button {
	border: 1px solid #2585fb;
	height: 37px;
	padding: 10px 30px;
	color: white;
	background-color: #2585fb;
	border-radius: 2px;
	@apply --c;
	font-size: 17px;
	line-height: 1;
	text-transform: uppercase;
	transition: color .3s, background-color .3s, border-color .3s;
}

.contacts--content__form form  button:hover {
	color: #2585fb;
	background-color: transparent;
}