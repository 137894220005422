.item--details__wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.item--details__wrapper .item__accordion,
.item--details__wrapper .item__video.index--about__video,
.item--details__wrapper .item__photo,
.item--details__wrapper .item__text {
	flex-basis: 100%;
	width: 100%;
	margin-bottom: 40px;
	margin-top: 0;
}

.item--details__wrapper .item__accordion .tab-content .tab-pane {
	max-height: 100vh;
}

.item--details__wrapper .item__accordion .tab-content .tab-pane::-webkit-scrollbar {
	width: 8px;
}

.item--details__wrapper .item__accordion .tab-content .tab-pane::-webkit-scrollbar-track {
	background-color: #f6f6f6;
	border-radius: 10px;
}
.item--details__wrapper .item__accordion .tab-content .tab-pane::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #2585FB;
}

.item--details__wrapper .item__video.index--about__video {
	order: 1;
	display: grid;
	width: 100%;
	grid-template-columns: repeat(2, calc(50% - 20px));
	grid-gap: 40px;
	align-items: start;
}

@media (width <= 1199px) {
	.item--details__wrapper .item__video.index--about__video {
		grid-template-columns: 100%;
	}
}

.item--details__wrapper .item__video.index--about__video .youtube {
	width: 100%!important;
}

.item--details__wrapper .index--about__advantages {
	padding-left: 0;
	padding-right: 0;
}

.item--details__wrapper .index--about__advantages:only-child {
	grid-column: 1 / -1;
}

.item--details__wrapper .index--about__advantages > a {
	margin-top: 0;
	height: auto;
	line-height: normal;
	padding-bottom: 10px;
}

.item--details__wrapper .index--about__advantages__wrapper {
	padding-top: 20px;
	padding-bottom: 0;
}

.item--details__wrapper .item__photo {
}

.item--details__wrapper .item__photo__wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	grid-gap: 10px;
	margin-left: 0;
	margin-right: 0;
}

.item--details__wrapper .item__photo__wrapper a {
	width: 100%;
	padding: 0;
}

.item--details__wrapper .item__text {order: -1;}

@media (width >= 1200px) {
	table {
		display: table;
	}
}
@media (width <= 1199px) {
	table {
		display: block;
	}
}


.video-slider {
	width: 100%;
	position: relative;
}

.video-slider__nav {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.video-slider__nav span {
	display: block;
	width: 24px;
	height: 24px;
	position: absolute;
	top: calc(50% - 12px);
	cursor: pointer;
	transform: scale(1);
	transition: transform 1s;
	pointer-events: all;
}

.video-slider__nav span:hover {transform: scale(1.5);}
.video-slider__nav--left {left: 10px;}
.video-slider__nav--right {right: 10px;}
.video-slider__nav--right svg {transform: rotate(180deg);}

.video-slider__nav svg {
	width: 100%;
	height: 100%;
}

.video-slider__nav .pageFill {
	fill: white;
	transition: fill .3s;
}

.video-slider__nav span:hover .pageFill {fill: #f6c97c;}