.header-bottom-logo {
	flex-basis: 360px;
	position: relative;
	
}
.header-bottom-logo__wrapper {
	background-color: #2585fb;
	border-radius: 2px;
	margin-bottom: 20px;
	position: relative;
}

.header-bottom-logo .warning-link {
	color: white;
	font-size: 14px;
	border-radius: 2px;
	overflow: hidden;
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	height: 40px;
	background-color: #d74148;
	transition: background-color .3s;
}

.header-bottom-logo .warning-link span {
	flex-basis: auto;
	max-width: 100%;
	padding-left: 5px;
	padding-right: 5px;
	@apply --c;
	font-weight: normal;
	line-height: 40px;
	font-size: 26px;
	line-height: 40px;
	position: relative;
	letter-spacing: .5px;
}

.header-bottom-logo .warning-link span::after {
	width: 20px;
	height: 20px;
	border: 1px solid #df9195;
	position: absolute;
	display: block;
	top: 9px;
	right: -28px;
	content: "";
}

.header-bottom-logo .warning-link:hover {
	background-color: #9c0c13;
}

.header-bottom-logo .warning-link svg {
	transform: rotate(180deg);
	stroke: white;
	stroke-width: 1px;
	stroke-linecap: round;
	margin-left: 15px;
	position: relative;
	top: 13px;
	display: inline-block;
}

.header-bottom-logo .warning-link svg path {
	fill: white;
}

@media (width <= 991px) {
	.header-bottom-logo .warning-link {
		width: 360px;
		max-width: 100%;
		position: static;
		margin: 10px auto;
	}
}

@media (width <= 767px) {	
	.header-bottom-logo .warning-link span {
		letter-spacing: 0;
		font-size: 20px;
	}
}

.header-bottom-logo p {
	color: white;
	@apply --m;
	font-size: 18px;
	margin-left: 100px;
	margin-bottom: 0;
	text-shadow: 1px 1px 4px #1f1f1f;
	background-color: transparent;
}

@media (width >= 992px) {
	.header-bottom-logo__wrapper {
		margin-bottom: 0;
	}
	
	.header-bottom-logo p {
		margin-left: 0;
		margin-bottom: 0;
		padding-left: 35px;
		padding: 10px;
		color: #2585fb;
		text-shadow: none;
		font-size: 18px;
		font-family: "Myriad Pro", sans-serif;
		background-color:white;
	}
} 



.is-fixed .header-bottom-logo {
	flex-basis: auto;
}

.is-fixed .header-bottom-logo p {
	display: none;
}

.is-fixed .header-bottom-logo__wrapper {
	background-color: transparent;
	margin-bottom: 15px;
}

.is-fixed .header-bottom-logo__wrapper a {
	min-height: auto;
}

.is-fixed .header-bottom-logo__wrapper a img {
	width: 154px;
	height: 58px;
}

.is-fixed .header-bottom-logo .warning-link {
	display: none;
}

@media (width >= 1200px) {
	.is-fixed .header-bottom-logo {
		max-width: 220px;
	}
}

@media (width >= 1440px) {
	.is-fixed .header-bottom-logo {
		max-width: none;
	}
}