.site-footer__contacts {
	flex-basis: auto;
	padding-top: 30px;
	padding-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

@media (width <= 1199px) {
	.site-footer__contacts {
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 30px;
	}
}

@media (width <= 991px) {
	.site-footer__contacts {
		align-items: center;
		padding-top: 0;
	}	
}

@media (width <= 767px) {
	.site-footer__contacts {
		padding-top: 20px;
	}	
}

.site-footer__social {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 10px;
}

.site-footer__social a {
	width: 33px;
	height: 33px;
	margin-right: 18px;
	border: 1px solid #d9d6d6;
	border-radius: 50%;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color .3s, border-color .3s;
}

@media (width <= 1599px) {
	.site-footer__social a {
		margin-right: 4px;
	}
}

.site-footer__social a:hover {
	border-color: #2585fb;
	background-color: #2585fb;
}

.site-footer__social .iconMainColor {
	fill: #bac0c9;
	transition: fill .3s;
}

.site-footer__social a:hover .iconMainColor {
	fill: white;
}

.site-footer__contacts p {
	text-align: left;
}

.site-footer__contacts p,
.site-footer__contacts a {
	@apply --m;
	color: #01102a;
	font-size: 17px;
	margin-bottom: 5px;
}

.site-footer__contacts a {
	@apply --transitionOpacityStart;
}

.site-footer__contacts a:hover {
	@apply --transitionOpacityEnd;
}

.footer--tablet {
	display: none;
}

.site-footer__contacts .footer-email {
	text-align: right;
	display: block;
	@apply --m;
	color: #2585fb;
	font-size: 16px;
	margin-bottom: 10px;
}

@media (width <= 1499px) {
	.site-footer__contacts {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (width <= 991px) {
	.site-footer__contacts {
		flex-basis: 100%;
	}

	.site-footer__social {
		justify-content: center;
	}

	.site-footer__social a {
		margin-left: 9px;
		margin-right: 9px;
	}

	.site-footer__contacts p,
	.site-footer__contacts .footer-email {
		text-align: center;
	}
}

@media (width <= 767px) {
	.site-footer__contacts p,
	.site-footer__contacts .footer-email {
		display: none;
	}

	.site-footer__social {
		margin-bottom: 0;
	}

	.footer--tablet {
		margin-top: 20px;
		display: block;
	}

	.footer--tablet p {
		display: block;
		margin-bottom: 0;
		color: #103a6e;
		@apply --m;
		font-size: 20px;
	}
}

@media (width <= 479px) {
	.site-footer__social a {
		margin-right: 5px;
		margin-left: 5px;
	}
	
	.site-footer__contacts {
		padding-left: 0;
		padding-right: 0;
	}

	.site-footer__contacts p > a {
		display: block;
	}
}