.newsItemLink__wrapper {
	display: flex;
	width: 100%;
	justify-content: center;
}

.newsItemLink__wrapper .newsItemLink {
	width: auto;
	height: auto;
	background-color: #2585fb;
	border: 1px solid #2585fb;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	@apply --c;
	font-size: 14px;
	line-height: 1.5;
	padding: 5px 15px;
	text-transform: uppercase;
	text-align: center;
	transition: background-color .3s, color .3s;
}

.newsItemLink__wrapper .newsItemLink:hover {
	background-color: transparent;
	color: #2585fb;
}

.item__slider.item__slider--titles {
	margin-left: auto;
	margin-right: auto;
}

.item__slider.item__slider--titles .item {
	min-height: 600px;
	height: auto;
}

.item__slider.item__slider--titles .item img {
	height: 600px;
}

@media (max-width: 991px) {
	.item__slider.item__slider--titles .item {
		min-height: 400px;
		height: auto;
	}

	.item__slider.item__slider--titles .item img {
		height: 400px;
	}	
}

.carousel__title {
	color: #01102a;
	text-transform: uppercase;
	text-align: center;
	font-size: 20px;
	font-family: "Myriad Pro Bold", sans-serif;
	margin-top: 20px;
	margin-bottom: 20px;
	display: block;
}

.dealer--content--light {
	padding: 15px;
}

@media (width >= 992px) {
	.dealer--content--light {
		margin-top: 40px;
	}
}