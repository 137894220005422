.dealer__card {
	border: 1px solid #2585fb;
	padding: 40px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 30px;
}

.dealer__card:nth-last-child(2) {
	margin-bottom: 40px;
}

.dealer__card-text {
	flex-basis: calc(100% - 470px);
}

@media (width <= 1199px) {
	.dealer__card-text {
		flex-basis: 100%;
	}
}

.dealer__card h2 {
	color: #01102a;
	@apply --c;
	font-size: 24px;
	margin-bottom: 50px;
}

.dealer__card-text p {
	@apply --m;
	font-size: 22px;
	color: #7d838e;
	margin-bottom: 10px;
}

.dealer__card-text p a {
	color: #328cfb;
}

.dealer__card-text p a:hover {
	text-decoration: underline;
	text-decoration-style: dotted;
}

.dealer__card-infographic {
	flex-basis: 450px;
	position: relative;
	left: 80px;
	background-color: #EFEFEF;
	padding: 20px;
	max-width: 100%;
}

@media (width <= 1199px) {
	.dealer__card-infographic {
		margin-top: 20px;
		margin-left: auto;
		left: 0;
	}
}

.dealer__card-infographic p {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	color: #01102a;
	@apply --c;
	font-size: 16px;
}

.dealer__card-infographic p span {
	font-size: 14px;
	display: inline-block;
	margin-left: 10px;
}

.infographic {
	display: flex;
	justify-content: space-between;
}

.infographic-item:not(:last-child) {
	margin-bottom: 25px;
}

.infographic span {
	display: inline-block;
	flex-basis: calc(20% - 2px);
	height: 4px;
	background-color: #aab0b7;
}

.infographic span.active {
	background-color: #2585fb;
}