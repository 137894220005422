.breadcrumb {
	width: 100%;
	flex-basis: 100%;
	padding-top: 45px;
	padding-bottom: 25px;
	text-align: center;
	background-color: transparent;
	margin-bottom: 0;
}

.is-fixed .breadcrumb {
	display: none;
}

@media (width >= 768px) {
	.breadcrumb {
		padding-left: 0;
		padding-right: 0;		
	}
}

.breadcrumb li a {
	display: inline-block;
	@apply --c;
	font-size: 17px;
	text-transform: uppercase;
	padding: 10px 0;
	line-height: 1;
	color: #ffce78;
	@apply --transitionOpacityStart;
}

.breadcrumb li:not(.active) a:hover {
	@apply --transitionOpacityEnd;
}

.breadcrumb .active a {
	color: #efefef;
	cursor: default;
}

.breadcrumb a:focus {
	text-decoration: none;
}

.breadcrumb > li + li {
	margin-left: 50px;
	position: relative;
}

.breadcrumb > li + li::before {
	content: "";
	display: block;
	position: absolute;
	left: -32px;
	top: 14px;
	width: 12px;
	height: 7px;
	transform: rotate(-90deg);
	background-image: url(../img/option.png);
}

@media (width <= 767px) {
	.breadcrumb {
		display: none;
	}
}