.contract-alert {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 40px;
	background-color: #d74148;
	opacity: 0.9;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.contract-alert p {
	font-family: "Myriad Pro", sans-serif;
	color: white;
	font-size: 22px;
	letter-spacing: 1.1px;
	margin-bottom: 0;
	padding-left: 10px;
	padding-right: 10px;
}

.contract-alert a {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-width: 215px;
	height: 40px;
	border-radius: 2px;
	background-color: #2585fb;
	margin-left: 10px;
	margin-right: 10px;
	font-family: "Myriad Pro", sans-serif;
	color: white;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 1.4px;
	transition: opacity .3s;
	text-transform: uppercase;
}

.contract-alert a:hover {
	text-decoration: none;
	opacity: 0.8;
}

@media (width <= 991px) {
	.contract-alert {
		flex-direction: column;
		padding: 10px;
	}
	.contract-alert p {
		margin-bottom: 10px;
		text-align: center;
	}
}

@media (width <= 767px) {
	.index--slider {
		position: relative!important;
	}
	.index--slider .container-fluid {
		display: none!important;
	}
	.contract-alert {
		position: relative;
	}
}