.item--details__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
}

.item--details .item__video.index--about__video {
	margin-top: 40px;
}

.item--details .index--about__advantages {
	flex-basis: 100%;
	max-width: 100%;
}

.additionalVideo {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.additionalVideo__wrapper {
	width: calc(50% - 10px);
}

@media (width <= 641px) {
	.additionalVideo {
		flex-wrap: wrap;
	}
	.additionalVideo__wrapper {
		width: 100%;
		margin-bottom: 20px;
	}
}